import Vue from 'vue'

const features = new Vue({
  name: 'features',
  data() {
    return {
      catalog: true,
      library: true,
      ordering: true,
      maintenance: true
    }
  }
})

export default features
