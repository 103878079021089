import Vue from 'vue'

window.$ = require('jquery')

Vue.prototype._ = require('lodash')

Vue.prototype.$log = (...stuff) => { console.log(stuff) }

Vue.prototype.$toJSON = (object) => {
  //return JSON.parse(JSON.stringify(object)) // Commented by Deepti as it was changing keys to lowercase
  return _.cloneDeep(object)
}

var sanitizeHtml = require('sanitize-html');
Vue.prototype.$sanitize = (dirty) => {
  return sanitizeHtml(dirty, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ])
  })
}

Vue.directive("autosize", function(el) {
  Vue.nextTick(() => {
    var textarea = el.querySelector("textarea")
    if (textarea) {
      textarea.setAttribute("style", "min-height:" + textarea.scrollHeight + "px;")
    }
  })
})

Vue.filter("formatNumber", function (value) {
  let result = _.toNumber(value).toLocaleString()
  if (result == 'NaN') return ''
  return result
});
