require('lodash')
export default {
  methods: {
    storageKey() { return this.$options.name || this.$route.name || this.$route.path }
  },
  watch: {
    $data: {
      handler(value) {
        // console.log("localStorage ", value, this.storageKey(),JSON.stringify(value));
        localStorage.setItem(this.storageKey(), JSON.stringify(value))
      },
      deep: true
    }
  },
  created() {
    console.log(this.storageKey())
    if (localStorage.getItem(this.storageKey())) {
      _.assign(this, JSON.parse(localStorage.getItem(this.storageKey())))
    }
  }
}
