import Vue from 'vue'
import router from '../_main/router'
import localData from "../mixins/localData"

function defaultData() {
  return {
    showGridView: false,
  }
}

const state = new Vue({
  name: "state",
  router: router,
  mixins: [localData],
  data() {
    return defaultData()
  }
})

export default state

Vue.mixin({
  data(){
    return {
      inEditMode: false,
      dataReady: false,
    }
  },
})
