import Router from 'vue-router'
import auth from '@/_main/auth'

import NProgress from 'nprogress'

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      name: 'root',
      path: '/',
      component: () => import('../views/UserDashboard_new.vue')
    },
    {
      name: 'demo',
      path: '/demo',
      component: () => import('../views/DemoPage.vue')
    },
    // {
    //   name: 'test',
    //   path: '/test',
    //   component: () => import('../components/tabs/TabsWrapper')
    // },
    {
      name: 'login',
      path: '/login',
      component: () => import('../views/Login')
    },
    {
      name: 'profile',
      path: '/profile',
      component: () => import('../views/UserProfile'),
      props: { id: _.get(auth.currentUser, 'id') }
    },

    {
      name: 'acceptInivation',
      path: '/invitation/accept',
      // path: "/set_password",
      // path: '/auth/activate/:uid/:token',
      component: () => import('../views/AcceptInivation'),
      meta: { authNotRequired: true }
    },

    {
      name: 'forget_password',
      path: '/forget_password',
      component: () => import('../views/ForgetPassword'),
      meta: { authNotRequired: true }
    },

    {
      name: 'set_password',
      //path: '/setpassword/:user_id',
      // path: "/set_password",
      path: '/setpassword/:uid/:token',
      component: () => import('../views/ResetPassword'),
      meta: { authNotRequired: true }
    },

    {
      name: 'change_password',
      path: '/change_password',
      component: () => import('../views/ChangePassword')
    },

    {
      name: 'contact',
      path: '/contact',
      component: () => import('../views/Contact')
    },
    {
      name: 'assetTagCode',
      path: '/asset_tag/:code',
      component: () => import('../views/AssetTagCode'),
      props: true
    },
    {
      name: 'map',
      path: '/map',
      component: () => import('../components/GoogleMap')
    },

    {
      name: 'admin',
      path: '/admin',
      component: () => import('../components/Folder'),
      beforeEnter: adminGuard,
      children: [
        {
          name: 'users',
          path: 'users',
          component: () => import('../views/Users')
        },
        {
          name: 'user',
          path: 'user/:id',
          component: () => import('../views/UserProfile'),
          props: true
        },
        {
          name: 'customers',
          path: 'customers',
          component: () => import('../views/Customers')
        },
        {
          name: 'customer',
          path: 'customer/:id',
          component: () => import('../views/Customer'),
          props: true
        },

        {
          name: 'countries',
          path: 'countries',
          component: () => import('../views/Countries')
        },
        // { name: 'newCountry',  path: 'countries/new',    component: ()=> import('../views/Country'), props: {id: 'new'}},
        // { name: 'Country',    path: 'country/:id', component: ()=> import('../views/Country'),   props: true},

        {
          name: 'manufacturers',
          path: 'manufacturers',
          component: () => import('../views/Manufacturers')
        },
        {
          name: 'application_types',
          path: 'application_types',
          component: () => import('../views/ApplicationTypes')
        },
        {
          name: 'application_type',
          path: 'application_type/:id',
          component: () => import('../views/ApplicationType'),
          props: true
        },
        {
          name: 'material_types',
          path: 'material_types',
          component: () => import('../views/MaterialTypes')
        },
        {
          name: 'material_type',
          path: 'material_type',
          component: () => import('../views/MaterialType'),
          props: true
        },
        {
          name: 'assembly_types',
          path: 'assembly_types',
          component: () => import('../views/AssemblyTypes')
        },
        {
          name: 'assembly_type',
          path: 'assembly_type',
          component: () => import('../views/AssemblyType'),
          props: true
        },

        {
          name: 'assembly_classifications',
          path: 'assembly_classifications',
          component: () => import('../views/AssemblyClassifications')
        },
        {
          name: 'assembly_classification',
          path: 'assembly_classification',
          component: () => import('../views/AssemblyClassification'),
          props: true
        },
        {
          name: 'connection_types',
          path: 'connection_types',
          component: () => import('../views/ConnectionTypes')
        },
        {
          name: 'connection_type',
          path: 'connection_type/:id',
          component: () => import('../views/ConnectionType'),
          props: true
        },
        {
          name: 'packing_types',
          path: 'packing_types',
          component: () => import('../views/PackingTypes')
        },
        {
          name: 'packing_type',
          path: 'packing_type/:id',
          component: () => import('../views/PackingType'),
          props: true
        },
        {
          name: 'styles',
          path: 'styles',
          component: () => import('../views/Styles')
        },
        {
          name: 'style',
          path: 'style/:id',
          component: () => import('../views/Style'),
          props: true
        },
        {
          name: 'part_classifications',
          path: 'part_classifications',
          component: () => import('../views/PartClassifications')
        },
        {
          name: 'part_classification',
          path: 'part_classification/:id',
          component: () => import('../views/PartClassification'),
          props: true
        },
        {
          name: 'fluid_ends',
          path: 'fluid_ends',
          component: () => import('../views/FluidEnds')
        },
        {
          name: 'fluid_end',
          path: 'fluid_end/:id',
          component: () => import('../views/FluidEnd'),
          props: true
        },
        {
          name: 'power_ends',
          path: 'power_ends',
          component: () => import('../views/PowerEnds')
        },
        {
          name: 'power_end',
          path: 'power_end/:id',
          component: () => import('../views/PowerEnd'),
          props: true
        },
        {
          name: 'measurement_units',
          path: 'measurement_units',
          component: () => import('../views/MeasurementUnits')
        },
        {
          name: 'measurement_unit',
          path: 'measurement_unit/:id',
          component: () => import('../views/MeasurementUnit'),
          props: true
        },
        {
          name: 'sizes',
          path: 'sizes',
          component: () => import('../views/Sizes')
        },
        {
          name: 'size',
          path: 'size/:id',
          component: () => import('../views/Size'),
          props: true
        },
        {
          name: 'newManufacturer',
          path: 'manufacturers/new',
          component: () => import('../views/Manufacturer'),
          props: { id: 'new' }
        },
        {
          name: 'manufacturer',
          path: 'manufacturer/:id',
          component: () => import('../views/Manufacturer'),
          props: true
        },

        {
          name: 'adminQuotes',
          path: 'quotes',
          component: () => import('../views/AdminQuotes')
          // props: { isAdm: true },
        },
        {
          name: 'adminQuote',
          path: 'quote/:id',
          component: () => import('../views/AdminQuote'),
          props: true
        },

        {
          name: 'adminOrders',
          path: 'orders',
          component: () => import('../views/AdminOrders')
        },
        {
          name: 'adminOrder',
          path: 'order/:id',
          component: () => import('../views/AdminOrder'),
          props: true
        },
        {
          name: 'assetTags',
          path: 'asset_tags',
          component: () => import('../views/AssetTags')
        },
        {
          name: 'assetTag',
          path: 'asset_tag/:id',
          component: () => import('../views/AssetTag'),
          props: true
        },
        {
          name: 'adminPartsOrders',
          path: 'parts_orders',
          component: () => import('../views/AdminPartsOrders')
        },
        {
          name: 'adminPartsOrder',
          path: 'parts_order/:id',
          component: () => import('../views/AdminPartsOrder'),
          props: true
        },
        {
          name: 'operationsChecklistItems',
          path: 'operations_checklist_items',
          component: () => import('../views/OperationsChecklistItems')
        },
        {
          name: 'operationsChecklistItem',
          path: 'operations_checklist_item/:id',
          component: () => import('../views/OperationsChecklistItem'),
          props: true
        },
        {
          name: 'maintenance_instructions_mappings',
          path: 'maintenance_instructions_mappings',
          component: () => import('../views/MaintenanceInstructionsMappings')
        },
        {
          name: 'maintenance_instructions_mapping',
          path: 'maintenance_instructions_mapping/:id',
          component: () => import('../views/MaintenanceInstructionsMapping'),
          props: true
        },
        {
          name: 'fluid_end_connection_types',
          path: 'fluid_end_connection_types',
          component: () => import('../views/FluidEndConnectionTypes')
        },
        {
          name: 'fluid_end_connection_type',
          path: 'fluid_end_connection_type/:id',
          component: () => import('../views/FluidEndConnectionType'),
          props: true
        },
        {
          name: 'subscriptions',
          path: 'subscriptions',
          component: () => import('../views/Subscriptions')
        },
        {
          name: 'subscription',
          path: 'subscription/:id',
          component: () => import('../views/Subscription'),
          props: true
        },
        {
          name: 'pump_fields_customers_permissions',
          path: 'pump_fields_customers_permissions',
          component: () =>
            import('../views/UserPumpFieldsCustomersPermission.vue')
        },
        {
          name: 'casing_sizes',
          path: 'casing_sizes',
          component: () => import('../views/CasingSizes')
        },
        {
          name: 'casing_size',
          path: 'casing_size/:id',
          component: () => import('../views/CasingSize'),
          props: true
        },
        {
          name: 'email_templates',
          path: 'email_templates',
          component: () => import('../views/EmailTemplate')
        },
        {
          name: 'maintenance_schedules',
          path: 'maintenance_schedules',
          component: () => import('../views/MaintenanceSchedule')
        },

        {
          name: 'troubleshooting_info',
          path: 'troubleshooting_info',
          component: () => import('../views/Troubleshooting')
        },
        {
          name: 'torque_specifications',
          path: 'torque_specifications',
          component: () => import('../views/TorqueSpecifications')
        },
        {
          name: 'pump_specifications',
          path: 'pump_specifications',
          component: () => import('../views/PumpSpecifications')
        },
        {
          name: 'pump_specification',
          path: 'pump_specification/:id',
          component: () => import('../views/PumpSpecification'),
          props: true
        },
        {
          name: 'operating_instructions',
          path: 'operating_instructions',
          component: () => import('../views/OperatingInstructions')
        },
        {
          name: 'search_classification',
          path: 'search_classification',
          component: () => import('../views/SearchClassification')
        },
        {
          name: 'userPumpJobsAdmin',
          path: 'jobs',
          component: () => import('../components/UserPumpJobs'),
          props: { isAdminPage: true },
        },
      ]
    },

    {
      name: 'catalog',
      path: '/catalog',
      component: () => import('../components/Folder'),
      beforeEnter: canViewCatalog,
      children: [
        {
          name: 'drawings',
          path: 'drawings',
          component: () => import('../views/Drawings')
        },
        {
          name: 'drawing',
          path: 'drawing/:id',
          // component: () => import('../components/PartDrawingModal'),
          component: () => import('../views/Drawing'),
          props: true
        },

        {
          name: 'kits',
          path: 'kits',
          component: () => import('../views/Kits')
        },
        {
          name: 'kit',
          path: 'kit/:id',
          component: () => import('../views/Kit'),
          props: true
        },

        
        {
          name: 'parts',
          path: 'parts',
          component: () => import('../views/Parts')
        },
        {
          name: 'partsSearchLanding',
          path: 'parts_search_landing',
          component: () => import('../views/PartsSearchLanding')
        },
        {
          name: 'partsSearch',
          path: 'parts_search',
          component: () => import('../views/PartsSearch')
        },
        {
          name: 'part',
          path: 'part/:id',
          component: () => import('../views/Part'),
          props: true
        },
        {
          name: 'interchangeable_parts',
          path: 'interchangeable_parts',
          component: () => import('../views/InterchangeableParts')
        },
        {
          name: 'interchangeable_part',
          path: 'interchangeable_part/:id',
          component: () => import('../views/InterchangeablePart'),
          props: true
        },
        {
          name: 'pumps',
          path: 'pumps',
          component: () => import('../views/Pumps')
        },
        {
          name: 'pumpsSearch',
          path: 'pumps_search',
          component: () => import('../views/PumpsSearch')
        },
        {
          name: 'pumpManufacturer',
          path: 'pumps/manufacturer/:id/:name',
          component: () => import('../views/PumpManufacturer'),
          props: true
        },
        // {
        //   name: 'newPump',
        //   path: 'pump/new',
        //   component: () => import('../views/Pump'),
        //   props: { id: 'new' },
        // },
        {
          name: 'pump',
          path: 'pump/:id',
          component: () => import('../views/Pump'),
          props: true
        },
        //Deepti
        {
          name: 'equipments',
          path: 'equipments',
          component: () => import('../views/Equipments')
        },
        //Deepti
        {
          name: 'equipment',
          path: 'equipment/:id',
          component: () => import('../views/Equipment'),
          props: true
        }
      ]
    },
    {
      name: 'rfqConfirmation',
      path: '/confirmation/rfq',
      component: () => import('../views/Cart'),
      props: { status: 'confirmation' }
    },

    {
      name: 'library',
      path: '/library',
      component: () => import('../components/Folder'),
      beforeEnter: canViewLibrary,
      children: [
        {
          name: 'cart',
          path: 'cart',
          component: () => import('../views/Cart'),
          beforeEnter: canOrder
        },
        {
          name: 'rfq',
          path: 'rfq',
          component: () => import('../views/Cart'),
          props: { status: 'rfq' },
          beforeEnter: canOrder
        },
        {
          name: 'order',
          path: 'order',
          component: () => import('../views/Cart'),
          props: { status: 'order' },
          beforeEnter: canOrder
        },
        {
          name: 'recommended_kits',
          path: 'recommended_kits',
          component: () => import('../views/RecommendedKits')
        },
        {
          name: 'customerDashboard',
          path: 'dashboard/:id/:name',
          component: () => import('../views/UserDashboard_new.vue'),
          props: true
        },
        {
          name: 'userDashboard',
          path: 'dashboard',
          component: () => import('../views/UserDashboard_new.vue')
        },
        {
          name: 'myDashboard',
          path: 'myDashboard',
          component: () => import('../views/MyDashboard.vue')
        },
        {
          name: 'userLocations',
          path: 'locations',
          component: () => import('../views/UserLocations')
        },
        {
          name: 'location',
          path: 'location/:id',
          component: () => import('../views/UserLocation'),
          props: true
        },
        {
          name: 'locationsMap',
          path: 'locations-map',
          component: () => import('../components/AppMap')
        },

        {
          name: 'userAssets',
          path: 'assets',
          component: () => import('../views/UserAssets')
        },
        {
          name: 'userAsset',
          path: 'asset/:id',
          component: () => import('../views/UserAsset'),
          props: true
        },
        //Deepti
        {
          name: 'userParts',
          path: 'parts',
          component: () => import('../views/UserParts')
        },
        {
          name: 'userPart',
          path: 'part/:id',
          component: () => import('../views/UserPart'),
          props: true
        },
        {
          name: 'replacement_parts',
          path: 'replacement_parts',
          component: () => import('../views/ReplacementParts')
        },
        {
          name: 'replacement_part',
          path: 'replacement_part/:id',
          component: () => import('../views/ReplacementPart'),
          props: true
        },
        {
          name: 'inventory_list',
          path: 'inventory_list',
          component: () => import('../views/InventoryList')
        },
        {
          name: 'inventory_item',
          path: 'inventory_item/:id',
          component: () => import('../views/InventoryItem'),
          props: true
        },
        {
          name: 'userKits',
          path: 'kits',
          component: () => import('../views/UserKits')
        },
        {
          name: 'userKit',
          path: 'kit/:id',
          component: () => import('../views/UserKit'),
          props: true
        },
        {
          name: 'userEquipments',
          path: 'equipments',
          component: () => import('../views/UserEquipments')
        },
        {
          name: 'userEquipment',
          path: 'equipment/:id',
          component: () => import('../views/UserEquipment'),
          props: true
        },
        {
          name: 'import',
          path: 'import',
          component: () => import('../views/Import')
        },
        {
          name: 'ImportPastHistory',
          path: 'import_history',
          component: () => import('../views/ImportPastHistory')
        },
        {
          name: 'importPastHistoryItems',
          path: 'import_history_items/:master_key',
          component: () => import('../views/ImportPastHistoryItems'),
          props: true
        },
        {
          name: 'importedUserPumpEditPage',
          path: 'import_history_item_edit/:id',
          component: () => import('../views/ImportedUserPumpEditPage'),
          props: true
        },

        {
          name: 'importOptions',
          path: 'import_options',
          component: () => import('../views/ImportOptions')
        },
        {
          name: 'userPumpGuidedImport',
          path: 'guided_import/:id',
          component: () => import('../views/UserPumpGuidedImport'),
          props: true
        },
        {
          name: 'importPump',
          path: 'import_pump',
          component: () => import('../views/ImportFromXLS.vue')
        },
        {
          name: 'userPumps',
          path: 'pumps',
          component: () => import('../views/UserPumps')
        },
        {
          name: 'userPump',
          path: 'pump/:id',
          component: () => import('../views/UserPump'),
          props: true
        },
        {
          name: 'userPumpJobs',
          path: 'jobs',
          component: () => import('../components/UserPumpJobs')
        },
        {
          name: 'userPumpJobNotes',
          path: 'jobs_notes',
          component: () => import('../views/UserPumpJobNotes')
        },
        {
          name: 'userPumpJobNotesList',
          path: 'notes_for_job/:job_id/:job_title',
          component: () => import('../views/UserPumpJobNotesList'),
          props: true
        },
        {
          name: 'inventory_replacement',
          path: 'inventory_replacement',
          component: () => import('../views/InventoryReplacement')
        },
        {
          name: 'inventory_replacement-calendar',
          path: 'inventory_replacement/calendar',
          component: () => import('../views/CalendarPage')
        },
        {
          name: 'inventory_replacement-kanban',
          path: 'inventory_replacement/kanban',
          component: () => import('../views/kanban/KanbanBoard')
        },
        {
          name: 'myUserPumpJobs',
          path: 'my_user_pump_jobs',
          component: () => import('../views/MyUserPumpJobs')
        },
        {
          name: 'myUserPumpJobs-calendar',
          path: 'my_user_pump_jobs/calendar',
          component: () => import('../views/CalendarPage')
        },
        {
          name: 'myUserPumpJobs-kanban',
          path: 'my_user_pump_jobs/kanban',
          component: () => import('../views/kanban/KanbanBoard')
        },
        {
          name: 'myJob',
          path: 'my_job/:id',
          component: () => import('../views/MyUserPumpJob'),
          props: true
        },
        {
          name: 'job',
          path: 'job/:id',
          component: () => import('../views/MyUserPumpJob'),
          props: true
        },
        {
          name: 'customerQuotes',
          path: 'quotes',
          component: () => import('../views/CustomerQuotes'),
          beforeEnter: canOrder
          // props: { isAdm: false },
        },
        {
          name: 'customerQuote',
          path: 'quote/:id',
          component: () => import('../views/CustomerQuote'),
          props: true,
          beforeEnter: canOrder
        },

        {
          name: 'customerOrders',
          path: 'orders',
          component: () => import('../views/CustomerOrders'),
          beforeEnter: canOrder
        },
        {
          name: 'customerOrder',
          path: 'order/:id',
          component: () => import('../views/CustomerOrder'),
          props: true,
          beforeEnter: canOrder
        },

        {
          name: 'partsOrders',
          path: 'parts_orders',
          component: () => import('../views/PartsOrders')
        },
        {
          name: 'partsOrder',
          path: 'parts_order/:id',
          component: () => import('../views/PartsOrder'),
          props: true
        },
        {
          name: 'partsOrderAgain',
          path: 'parts_order_duplicate/:id',
          component: () => import('../views/PartsOrderAgain'),
          props: true
        },
        {
          name: 'notes',
          path: 'notes',
          component: () => import('../views/Notes')
        },
        {
          name: 'newNote',
          path: 'notes/new',
          component: () => import('../views/Note'),
          props: { id: 'new' }
        },
        {
          name: 'note',
          path: 'note/:id',
          component: () => import('../views/Note'),
          props: true
        },
        {
          name: 'calculator',
          path: 'calculator',
          component: () => import('../views/Calculator')
        },
        {
          name: 'customerTeams',
          path: 'customer_teams',
          component: () => import('../views/master/CustomerTeams')
        },
        {
          name: 'customerTeam',
          path: 'customer_team/:id',
          component: () => import('../views/master/CustomerTeam'),
          props: true
        },
        {
          name: 'customerRegions',
          path: 'customerRegions',
          component: () => import('../views/master/CustomerRegions')
        },
        {
          name: 'customerRegion',
          path: 'customerRegion/:id',
          component: () => import('../views/master/CustomerRegion'),
          props: true
        },
        {
          name: 'vendors',
          path: 'vendors',
          component: () => import('../views/Vendors')
        },
        {
          name: 'vendor',
          path: 'vendor/:id',
          component: () => import('../views/Vendor'),
          props: true
        },
        {
          name: 'categories',
          path: 'categories',
          component: () => import('../views/Categories')
        },
        {
          name: 'category',
          path: 'category/:id',
          component: () => import('../views/Category'),
          props: true
        },
        {
          name: 'job_types',
          path: 'job_types',
          component: () => import('../views/JobTypes')
        },
        {
          name: 'job_type',
          path: 'job_type/:id',
          component: () => import('../views/JobType'),
          props: true
        },
        {
          name: 'part_tags',
          path: 'part_tags',
          component: () => import('../views/PartTags')
        },
        {
          name: 'part_tag',
          path: 'part_tag/:id',
          component: () => import('../views/PartTag'),
          props: true
        },
        {
          name: 'settings',
          path: 'settings',
          component: () => import('../views/Settings')
        },
      ]
    },

    {
      // name: 'user',
      path: '/user',
      component: () => import('../components/Folder'),
      children: [
        {
          name: 'pump_fields_users_permissions',
          path: 'pump_fields_users_permissions',
          component: () => import('../views/UserPumpFieldsUsersPermission.vue'),
          beforeEnter: currentAdmin
        },
        {
          name: 'pump_fields_customer_permissions',
          path: 'pump_fields_customer_permissions/:id',
          component: () =>
            import('../views/UserPumpFieldsCustomerPermission.vue'),
          props: true
        },
        {
          name: 'pump_fields_user_permissions',
          path: 'pump_fields_user_permissions/:id',
          component: () => import('../views/UserPumpFieldsPermission.vue'),
          props: true,
          beforeEnter: currentAdmin
        }
      ]
    }
  ]
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  console.log('auth.isSignedIn', auth.isSignedIn)

  if (routeFrom.name) {
    NProgress.start()
  }
  if (routeTo.matched.some(route => route.meta.authNotRequired)) return next()
  if (routeTo.name === 'login') return next()
  if (auth.isSignedIn) return next()
  next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
})

// When each route is finished evaluating...
//router.afterEach((routeTo, routeFrom) => {
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

function canViewLibrary(to, from, next) {
  if (auth.can.viewLibrary) return next()
  console.log('BLOCKING via canViewLibrary')
  next({ name: 'userDashboard' })
}

function canViewCatalog(to, from, next) {
  if (auth.can.viewCatalog) return next()
  console.log('BLOCKING via canViewCatalog')
  next({ name: 'userDashboard' })
}

function canOrder(to, from, next) {
  if (auth.can.order) return next()
  console.log('BLOCKING via canOrder')
  next({ name: 'userDashboard' })
}

function adminGuard(to, from, next) {
  if (auth.is.ieqAdmin || auth.is.admin) return next()
  console.log('BLOCKING via adminGuard')
  next({ name: 'userDashboard' })
}

function currentAdmin(to, from, next) {
  if (auth.is.ieqAdmin) {
    return next()
  }
  if (auth.is.admin) {
    if (to.params.id === 'new' || to.params.id === auth.id) {
      return next()
    }
  }
  console.log('BLOCKING via currentAdmin')
  next({ next: 'userDashboard' })
}

export default router
