import Vue from 'vue'
Vue.component('AppButton', () => import('../components/AppButton'))
Vue.component('AppIconButton', () => import('../components/AppIconButton'))
Vue.component('AppToolbar', () => import('../components/AppToolbar'))
Vue.component('FileUploaderExtended', () =>
  import('../components/FileUploaderExtended')
)
Vue.component('FileUploaderAdvanced', () =>
  import('../components/FileUploaderAdvanced')
)
Vue.component('FileUploader', () => import('../components/FileUploader'))
Vue.component('VueEditor', () => import('../components/VueEditor'))
Vue.component('AttachmentCard', () => import('../components/AttachmentCard'))
Vue.component('AttachmentsTable', () =>
  import('../components/AttachmentsTable')
)
// Vue.component('GlobalSearch', () => import('../components/GlobalSearch'))
// Vue.component('PdfThumbnail', () => import('../components/PdfThumbnail'))
