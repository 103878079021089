export default {
  //A
  accept_user_invitation_path() {
    return 'auth/users/activation/'
  },
  add_part_to_library() {
    return `api/user_parts/add_to_library/`
  },
  application_types_path() {
    return 'api/application_types/'
  },

  application_type_path(id) {
    return `api/application_types/${id}/`
  },
  application_types_for_model_path() {
    return `api/application_types/get_application_types_model/`
  },
  asset_categories_path() {
    return 'api/asset_categories/'
  },
  asset_category_path(id) {
    return `api/asset_categories/${id}/`
  },
  asset_tags_path() {
    return 'api/asset_tags/'
  },
  asset_tag_path(id) {
    return `api/asset_tags/${id}/`
  },
  asset_tag_code_path() {
    return `api/find_asset_code/`
  },
  assembly_types_path() {
    return 'api/assembly_types/'
  },
  assembly_type_path(id) {
    return `api/assembly_types/${id}/`
  },
  assembly_classifications_path() {
    return 'api/assembly_classifications/'
  },
  assembly_classification_path(id) {
    return `api/assembly_classifications/${id}/`
  },
  assign_locations_user_pumps_path() {
    return 'api/user_pumps/assign_location/'
  },
  archive_jobs_path() {
    return 'api/user_pump_jobs/update_archive/'
  },
  archive_parts_path() {
    return 'api/parts/update_archive/'
  },
  archive_replacement_parts_path() {
    return 'api/replacement_parts/update_archive/'
  },
  archive_kits_path() {
    return 'api/kits/update_archive/'
  },
  archive_equipments_path() {
    return 'api/equipments/update_archive/'
  },
  archive_pumps_path() {
    return 'api/pumps/update_archive/'
  },
  archive_models_path() {
    return 'api/models/update_archive/'
  },
  archive_drawings_path() {
    return 'api/drawings/update_archive/'
  },
  archive_asset_tags_path() {
    return 'api/asset_tags/update_archive/'
  },
  archive_user_parts_path() {
    return 'api/user_parts/update_archive/'
  },

  archive_user_kits_path() {
    return 'api/user_kits/update_archive/'
  },
  archive_user_pump_path() {
    return 'api/user_pumps/update_archive/'
  },

  favourite_parts_save_path() {
    return 'api/favourite_parts/'
  },
  favourite_parts_list_path() {
    return 'api/favourite_parts/get_favourites/'
  },
  favourite_parts_delete_path() {
    return `api/favourite_parts/delete_favourites/`
  },
  archive_user_equipment_path() {
    return 'api/user_equipments/update_archive/'
  },
  // attachment_preview_img_path(){
  //   return 'api/get_preview_base64_img/'
  // },
  //C
  casing_sizes_path() {
    return 'api/casing_sizes/'
  },
  casing_size_path(id) {
    return `api/casing_sizes/${id}/`
  },
  clone_record_path() {
    return 'api/clone_record/'
  },
  connection_types_path() {
    return 'api/connection_types/'
  },
  connection_type_path(id) {
    return `api/connection_types/${id}/`
  },
  countries_path() {
    return 'api/countries/'
  },
  country_path(id) {
    return `api/countries/${id}/`
  },
  customers_ddl_path() {
    return 'api/customers/customer_dd_list/'
  },
  customers_path() {
    return 'api/customers/'
  },
  customers_update_archive_or_mark_delete_path() {
    return 'api/customers/update_archive_or_mark_delete/'
  },
  customer_path(id) {
    return `api/customers/${id}/`
  },
  customer_teams_path() {
    return 'api/customer_teams/'
  },
  customer_team_path(id) {
    return `api/customer_teams/${id}/`
  },
  customer_team_dd_list_path() {
    return `api/customer_teams/customer_team_dd_list/`
  },

  customer_regions_path() {
    return 'api/customer_regions/'
  },
  customer_region_path(id) {
    return `api/customer_regions/${id}/`
  },

  customer_dashboard_path() {
    return 'api/customer_dashboard/'
  },
  my_dashboard_path() {
    return 'api/customer_dashboard/get_my_dashboard/'
  },
  my_dashboard_card_path() {
    return 'api/customer_dashboard/get_filtered_card_data/'
  },
  customer_dashboard_edit_path(id) {
    return `api/customer_dashboard/${id}/`
  },

  check_customer_name_return_suggestions_path() {
    return 'api/check_customer_name_return_suggestions/'
  },
  customer_quotes_path() {
    return 'api/customer_quotes/'
  },
  customer_quote_path(id) {
    // For customer
    return `api/customer_quotes/${id}/`
  },
  all_customer_submitted_quotes_path() {
    return 'api/customer_quotes/list_submitted_quotes/'
  },
  get_cart_data_for_customer_path() {
    return 'api/customer_quotes/get_cart_data_for_customer/'
  },
  // get_customer_quote_path(id){ // For admin
  //   return `api/customer_quotes/get_customer_quote/${id}/`
  // },
  customer_orders_path() {
    return 'api/customer_orders/'
  },
  customer_order_path(id) {
    return `api/customer_orders/${id}/`
  },
  parts_orders_path() {
    return 'api/parts_orders/'
  },
  parts_order_path(id) {
    return `api/parts_orders/${id}/`
  },
  create_library_customer_path(id) {
    return `api/customers/${id}/create_library/` // customer id
  },
  create_bulk_parts_path() {
    return 'api/parts/create_bulk/'
  },
  connection_types_for_model_path() {
    return `api/connection_types_for_model/`
  },
  customer_teams_path() {
    return 'api/customer_teams/'
  },
  customer_team_path(id) {
    return `api/customer_teams/${id}/`
  },

  customer_configs_path() {
    return 'api/customer_configs/'
  },
  customer_config_path(id) {
    return `api/customer_configs/${id}/`
  },
  customer_config_by_customer_id_path() {
    return 'api/customer_configs/get_config_by_customer_id/'
  },
  custom_fields_path() {
    return 'api/custom_fields/'
  },
  custom_field_path(id) {
    return `api/custom_fields/${id}/`
  },

  //D
  drawings_path() {
    return 'api/drawings/'
  },
  drawing_path(id) {
    return `api/drawings/${id}/`
  },
  drawings_base_list_path() {
    return 'api/drawings_base_list/'
  },
  drawing_materials_path() {
    return `api/drawing_materials/`
  },
  drawing_fluid_end_materials_path() {
    return `api/drawing_materials/drawing_material_for_fluid_end_and_size/`
  },
  drawing_plunger_materials_path() {
    return `api/drawing_materials/drawing_material_for_plunger_and_size/`
  },
  /**
   *
   * @param {Number} part_id
   * @returns
   */
  drawings_for_part_path() {
    return `api/drawings_for_part/`
  },
  drawings_for_part_with_meta() {
    return `api/drawings/drawings_for_part_with_meta/`
  },

  parts_search_drawings_path() {
    return `api/parts_search_drawings/`
  },
  parts_for_pump_path() {
    return 'api/parts_for_pump/'
  },
  //E

  equipments_path() {
    return 'api/equipments/'
  },
  equipment_path(id) {
    return `api/equipments/${id}/`
  },
  equipments_base_list_path() {
    return 'api/equipments_base_list/'
  },
  equipment_custom_fields_path() {
    return 'api/equipment_custom_fields/'
  },
  equipment_custom_field_path(id) {
    return `api/equipment_custom_fields/${id}/`
  },
  equipment_custom_fields_create_bulk_path() {
    return 'api/equipment_custom_fields/create_bulk_equipment_custom_fields/'
  },

  export_user_pumps_path() {
    return 'api/export_csv/'
  },
  export_manufacturer_path() {
    return 'api/export_manufacturer_csv/'
  },
  export_catalog_pumps_path() {
    return 'api/export_catalog_pumps/'
  },
  //F
  families_path() {
    return 'api/families/'
  },
  findByNumber_parts() {
    // return 'api/parts/findByNumber/'
    return 'api/parts/part_detail_by_number/'
  },

  //G

  generate_user_password_by_admin_path() {
    return 'api/users/generate_password/'
    // returns 204, when successfully created
    // returns 401, when requester not ieqAdmin or Customer Admin
    // returns 400 if password and confirm password do not match
    // returns 404, in case of other error
  },

  get_all_assigned_asset_tags_path() {
    return 'api/asset_tags/get_all_assigned_asset_tags/'
  },
  get_all_unassigned_asset_tags_path() {
    return 'api/asset_tags/get_all_unassigned_asset_tags/'
  },
  /**
   *
   * @param {Number} user_location_id
   * @returns
   */
  get_assets_on_user_location_path() {
    return 'api/user_locations/get_assets_on_user_location/'
  },
  get_region_locations_path() {
    return 'api/user_locations/get_region_locations/'
  },
  generate_hotspots_drawings_path() {
    return 'api/drawings/generate_hotspots/'
  },
  get_used_in_buckets_with_count_path() {
    return 'api/get_used_in_buckets_with_count/'
  },
  getPumpDataFromCatalog_path() {
    return 'api/pumps/get_pump_data_from_catalog/'
  },
  get_pumps_for_model_path() {
    return 'api/pumps/get_pumps_for_model/'
  },
  get_pumps_for_family_path() {
    return 'api/pumps/get_pumps_for_family/'
  },
  get_drawings_for_family_path() {
    return 'api/drawings/get_drawings_for_family/'
  },
  get_drawings_for_manufacturer_path() {
    return 'api/drawings/get_drawings_for_manufacturer/'
  },
  get_valve_assembly_types_for_fluid_end_path() {
    return 'api/drawings/get_valve_assembly_types_for_fluid_end/'
  },
  get_pre_signed_url_s3_path() {
    return 'api/get_pre_signed_url_s3/'
  },
  //I
  init_path() {
    return 'api/init/'
  },
  invite_user_path() {
    //return 'auth/users/resend_activation/'
    return 'api/users/send_activation_email/'
  },
  interchangeable_parts_path() {
    return 'api/interchangeable_parts/'
  },
  interchangeable_part_path(id) {
    return `api/interchangeable_parts/${id}/`
  },
  inventory_list_path() {
    return 'api/inventory/'
  },
  inventory_item_path(id) {
    return `api/inventory/${id}/`
  },
  job_types_path() {
    return 'api/job_types/'
  },
  job_type_path(id) {
    return `api/job_types/${id}/`
  },
  part_tags_path() {
    return 'api/part_tags/'
  },
  part_tag_path(id) {
    return `api/part_tags/${id}/`
  },
  replacement_part_path(id) {
    return `api/replacement_parts/${id}/`
  },
  replacement_parts_path() {
    return 'api/replacement_parts/'
  },
  replacement_parts_list_path() {
    return 'api/replacement_parts/replacement_parts_list/'
  },
  // import_user_pump_list_from_xls_path() {
  //   return 'api/user_pumps/import_user_pump_list_from_xls/'
  // },
  bulk_import_user_pump_list_from_xls_path() {
    return 'api/user_pumps/bulk_import_user_pump_list_from_xls/'
  },
  bulk_import_user_pump_history_path() {
    return 'api/user_pump_imports/'
  },
  bulk_import_user_pump_history_record_path(id) {
    return `api/user_pump_imports/${id}/`
  },
  bulk_import_user_pump_history_items_path() {
    return 'api/user_pump_import_items/'
  },
  bulk_import_user_pump_history_item_path(id) {
    return `api/user_pump_import_items/${id}/`
  },

  //K
  kits_path() {
    return 'api/kits/'
  },
  kit_path(id) {
    return `api/kits/${id}/`
  },
  //invite_user_path(user) {},
  //M
  manufacturers_deep_path() {
    return 'api/manufacturer/'
  },
  manufacturers_path() {
    return 'api/manufacturers/'
  },
  manufacturer_path(id) {
    return `api/manufacturers/${id}/`
  },
  maintenance_frequencies_path() {
    return 'api/maintenance_frequencies/'
  },
  maintenance_schedules_path() {
    return 'api/maintenance_schedules/'
  },
  maintenance_schedule_path(id) {
    return `api/maintenance_schedules/${id}/`
  },
  troubleshooting_info_path() {
    return 'api/troubleshooting_info/'
  },
  troubleshooting_obj_path(id) {
    return `api/troubleshooting_info/${id}/`
  },
  torque_specifications_path() {
    return 'api/torque_specifications/'
  },
  torque_specification_path(id) {
    return `api/torque_specifications/${id}/`
  },
  operating_instructions_path() {
    return 'api/operating_instructions/'
  },
  operating_instruction_path(id) {
    return `api/operating_instructions/${id}/`
  },
  operations_checklist_items_path() {
    return 'api/operations_checklists/'
  },
  operations_checklist_item_path(id) {
    return `api/operations_checklists/${id}/`
  },
  operations_checklist_logs_path() {
    return 'api/operations_checklist_logs/'
  },
  operations_checklist_log_path(id) {
    return `api/operations_checklist_logs/${id}/`
  },
  subscriptions_path() {
    return 'api/subscriptions/'
  },
  subscription_path(id) {
    return `api/subscriptions/${id}/`
  },
  user_pump_checklist_logs_path() {
    return 'api/user_pump_checklist_logs/'
  },
  user_pump_checklist_log_path(id) {
    return `api/user_pump_checklist_logs/${id}/`
  },
  user_pump_jobs_path() {
    return 'api/user_pump_jobs/'
  },
  user_pump_job_path(id) {
    return `api/user_pump_jobs/${id}/`
  },
  user_jobs_list_path() {
    return `api/user_pump_jobs/user_jobs_list/`
  },
  user_jobs_calendar_path() {
    return `api/user_pump_jobs/user_jobs_calendar/`
  },
  all_user_jobs_calendar_path() {
    return `api/user_pump_jobs/all_user_jobs_calendar/`
  },
  user_job_set_material_path() {
    return `api/user_pump_jobs/user_job_set_material/`
  },
  user_job_set_start_date_path() {
    return `api/user_pump_jobs/user_job_set_start_date/`
  },
  user_job_set_status_path() {
    return `api/user_pump_jobs/user_job_set_status/`
  },
  user_jobs_status_list_path() {
    return `api/user_pump_jobs/user_jobs_status_list/`
  },
  user_pump_job_notes_path() {
    return `api/user_pump_job_notes/`
  },
  user_pump_job_note_path(id) {
    return `api/user_pump_job_notes/${id}/`
  },
  /*
   * @param { number } user_job_id
   */
  user_pump_job_notes_for_job_path() {
    return `api/user_pump_job_notes/get_user_notes_for_job/`
  },

  //
  user_equipment_checklist_logs_path() {
    return 'api/user_equipment_checklist_logs/'
  },
  user_equipment_checklist_log_path(id) {
    return `api/user_equipment_checklist_logs/${id}/`
  },

  maintenance_instructions_mappings_path() {
    return 'api/pump_maintenance_instructions/'
  },
  maintenance_instructions_mapping_path(id) {
    return `api/pump_maintenance_instructions/${id}/`
  },

  material_types_path() {
    return 'api/material_types/'
  },
  material_type_path(id) {
    return `api/material_types/${id}/`
  },
  model_tree() {
    return 'api/model_tree/'
  },

  measurement_unit_path(id) {
    return `api/measurement_units/${id}/`
  },
  measurement_units_path() {
    return 'api/measurement_units/'
  },
  part_classifications_path() {
    return 'api/part_classifications/'
  },
  part_classification_path(id) {
    return `api/part_classifications/${id}/`
  },
  parts_by_classification_path() {
    return 'api/parts_by_classification/'
  },
  /**
   * @param { number }fe_size_id
   */
  part_classifications_for_fe_size() {
    return 'api/part_classifications_for_fe_size/'
  },
  /**
   * @param { number }fe_size_id
   * @param { number }part_classification_id
   */
  part_for_fe_size() {
    return 'api/part_for_fe_size/'
  },
  /**
   * @param { number }model_id
   * @param { number }assembly_classification_id
   */
  parts_for_power_end_assemblies_path() {
    return 'api/parts_for_power_end_assemblies/'
  },
  //parts_for_fluid_end_assemblies
  /**
   * @param { number }model_id
   * @param { number }assembly_classification_id
   */
  parts_for_fluid_end_assemblies_path() {
    return 'api/parts_for_fluid_end_assemblies/'
  },
  pump_specifications_path() {
    return 'api/pump_specifications/'
  },
  pump_specification_path(id) {
    return `api/pump_specifications/${id}/`
  },
  part_vendors_path() {
    return 'api/part_vendors/'
  },
  part_vendor_path(id) {
    return `api/part_vendors/${id}/`
  },
  get_pump_specifications_path() {
    return 'api/pump_specifications/get_pump_specification/'
  },
  get_assemblies_for_model_path() {
    return 'api/get_assemblies_for_model/'
  },
  fluid_ends_path() {
    return `api/fluid_ends/`
  },
  fluid_end_path(id) {
    return `api/fluid_ends/${id}/`
  },
  fluid_end_connection_types_path() {
    return `api/fluid_end_connection_types/`
  },
  fluid_end_connection_type_path(id) {
    return `api/fluid_end_connection_types/${id}/`
  },
  fluid_end_connection_types_ddl_path() {
    return `api/fluid_end_connection_types/get_fluid_end_connection_types/`
  },
  is_fluid_end_connection_type_already_exists_path() {
    return `api/fluid_end_connection_types/is_fluid_end_connection_type_already_exists/`
  },
  fluid_end_connection_types_ddl_for_userPump_path() {
    return `api/pump_applicable_fluid_end_connections/get_fluid_end_connection_types_for_catalog_pump/`
  },

  /**
   * @param { number }pump_cylinder_type
   */
  fluid_end_base_list_path() {
    return 'api/fluid_end_base_list/'
  },

  drawings_for_pump_maintenance_instructions() {
    return 'api/drawings_for_pump_maintenance_instructions/'
  },
  drawings_for_demo() {
    return 'api/drawings_for_demo/'
  },
  fluid_end_search_path() {
    return `api/fluid_end_search/`
  },
  fluid_end_parts_path(fe_id) {
    return `api/fluid_end_parts/${fe_id}/fluid_end_part_items/`
  },
  fluid_end_items_path(fe_id) {
    return `api/fluid_end_parts/${fe_id}/fluid_end_part_list/`
  },
  fluid_end_items_for_size_and_material_path(fe_id) {
    return `/api/fluid_end_parts/${fe_id}/fluid_end_part_list_for_size_and_material/`
  },
  fluid_ends_item_path() {
    return `api/fluid_end_parts/`
  },
  fluid_end_item_path(id) {
    return `api/fluid_end_parts/${id}/`
  },
  fluid_end_sizes_path() {
    return `api/fluid_end_sizes/`
  },

  power_end_parts_path() {
    return `api/power_end_parts/`
  },
  power_end_part_path(id) {
    return `api/power_end_parts/${id}/`
  },
  power_end_part_items_path(pe_id) {
    return `api/power_end_parts/${pe_id}/power_end_part_items/`
  },
  power_end_items_path(pe_id) {
    return `api/power_end_parts/${pe_id}/power_end_part_list/`
  },
  pump_part_items_path(pump_id) {
    return `api/pump_parts/${pump_id}/pump_part_items/`
  },
  power_ends_path() {
    return `api/power_ends/`
  },
  power_end_path(id) {
    return `api/power_ends/${id}/`
  },

  /**
   * @param { number }power_end_id
   * @returns
   */
  parts_classification_on_power_end() {
    return 'api/parts_classification_on_power_end/'
  },
  /**
   * @param { number }power_end_id
   * @param { number }part_classification_id
   * @returns
   */
  parts_on_classification_and_power_end() {
    return 'api/parts_on_classification_and_power_end/'
  },
  parts_by_classification_and_power_end() {
    return 'api/parts_by_classification_and_power_end/'
  },
  parts_by_classification_and_fluid_end() {
    return 'api/parts_by_classification_and_fluid_end/'
  },
  parts_for_material_type_path() {
    return 'api/parts_for_material_type/'
  },
  part_drawings_path() {
    return 'api/part_drawings/'
  },
  //N
  notes_path() {
    return 'api/notes/'
  },
  note_path(id) {
    return `api/notes/${id}/`
  },
  //P
  parts_path() {
    return 'api/parts/'
  },
  part_path(id) {
    return `api/parts/${id}/`
  },
  packing_sizes_path() {
    return 'api/packing_sizes/'
  },
  packing_types_path() {
    return 'api/packing_types/'
  },
  packing_type_path(id) {
    return `api/packing_types/${id}/`
  },
  pumps_path() {
    return 'api/pumps/'
  },
  pump_path(id) {
    return `api/pumps/${id}/`
  },
  pump_base_list() {
    return 'api/pump_base_list/'
  },
  pump_families_path() {
    return 'api/pump_families/'
  },
  pump_parts_path() {
    return 'api/pump_parts/'
  },
  pump_part_search_path() {
    return 'api/pumps/pump_part_search/'
  },
  pump_parts_by_end_type_path() {
    return 'api/pumps/pump_parts_by_end_type/'
  },

  //R
  recommended_items_path() {
    return 'api/parts/parts_list/'
  },
  recommended_user_parts_path() {
    return 'api/user_parts/'
  },
  reset_password_path() {
    return 'auth/users/reset_password/' // django
  },
  reset_password_confirm_path() {
    return 'auth/users/reset_password_confirm/' // django
  },
  //S
  search_classification_path() {
    return 'api/search_classification/'
  },
  send_enquiry_email_path() {
    return 'api/send_enquiry_email/'
  },
  search_path() {
    return 'api/search/'
  },
  set_password_path() {
    return 'auth/users/set_password/' // django
  },
  sizes_path() {
    return 'api/sizes/'
  },
  size_path(id) {
    return `api/sizes/${id}/`
  },
  sizes_by_type_path() {
    return 'api/sizes/get_sizes_by_type/'
  },
  states_path() {
    return 'api/states/'
  },
  state_path(id) {
    return `api/states/${id}/`
  },
  styles_path() {
    return 'api/styles/'
  },
  get_styles_by_packing_type_path() {
    return 'api/styles/get_styles_by_packing_type/'
  },
  style_path(id) {
    return `api/styles/${id}/`
  },
  share_pre_signed_url_via_email_path() {
    return 'api/share_pre_signed_url_via_email/'
  },
  //U
  uploads_path() {
    return 'api/uploads/'
  },
  upload_attachments_path() {
    return 'api/attachments/'
  },
  delete_attachment_path(id) {
    return `api/attachments/${id}/`
  },
  edit_attachment_path() {
    return `api/attachments/edit_label/`
  },
  update_view_count_path() {
    return `api/attachments/update_count/`
  },
  users_path() {
    return 'api/users/'
  },
  users_ddl_path() {
    return 'api/users/user_list/'
  },
  user_path(id) {
    return `api/users/${id}/`
  },
  user_tabs_order_path(id) {
    return `api/user_tabs_order/${id}/`
  },
  user_tabs_orders_path() {
    return `api/user_tabs_order/`
  },
  user_tabs_order_by_user_and_entity_path() {
    return `api/user_tabs_order/tabs_order_by_user/`
  },

  user_dashboard_config_path(id) {
    return `api/user_dashboard_config/${id}/`
  },
  user_dashboard_configs_path() {
    return `api/user_dashboard_config/`
  },
  user_dashboard_config_by_user_id_path() {
    return `api/user_dashboard_config/user_dashboard_config_by_user/`
  },

  user_parts_path() {
    return 'api/user_parts/'
  },
  user_part_path(id) {
    return `api/user_parts/${id}/`
  },
  user_part_logs_path() {
    return 'api/user_part_logs/'
  },
  user_part_log_path(id) {
    return `api/user_part_logs/${id}/`
  },
  user_part_associated_assets_path() {
    return `api/user_parts/user_part_associated_assets/`
  },
  user_parts_list_path() {
    return `api/user_parts/user_parts_list/`
  },
  parts_for_assemblies_kits_path() {
    return 'api/parts_for_assemblies_kits/'
  },
  packing_types_for_size_path() {
    return 'api/packing_types_for_size/'
  },
  parts_for_valve_assemblies_kits_path() {
    return 'api/parts_for_valve_assemblies_kits/'
  },
  parts_for_power_end_kit_path() {
    return 'api/parts_for_power_end_kit/'
  },
  parts_for_fluid_end_kit_path() {
    return 'api/parts_for_fluid_end_kit/'
  },
  kit_cominations_for_assemblies_path() {
    return 'api/kit_cominations_for_assemblies/'
  },
  recommended_kits_path() {
    return 'api/recommended_kits/'
  },
  recommended_piston_kits_path() {
    return 'api/recommended_piston_kits/'
  },
  recommended_piston_kit_parts_path() {
    return 'api/recommended_piston_kits/get_recommended_piston_kit/'
  },
  recommended_kit_detail_path() {
    return 'api/recommended_kits/get_recommended_kit_detail/'
  },
  recommended_kit_path(id) {
    return `api/recommended_kits/${id}/`
  },
  recommended_piston_kit_path(id) {
    return `api/recommended_piston_kits/${id}/`
  },
  recommended_piston_kits_for_model_path() {
    return 'api/import_recommended_kits_piston_for_model'
  },

  recommended_user_kits_path() {
    return 'api/recommended_user_kits/'
  },
  recommended_user_kit_path(id) {
    return `api/recommended_user_kits/${id}/`
  },
  // recommended_kit_path(id) {
  //   return `api/recommended_kits/${id}/`
  // },
  email_templates_path() {
    return 'api/email_templates/'
  },
  email_template_path(id) {
    return `api/email_templates/${id}/`
  },
  user_kits_path() {
    return 'api/user_kits/'
  },
  user_kit_path(id) {
    return `api/user_kits/${id}/`
  },

  user_equipments_path() {
    return 'api/user_equipments/'
  },
  user_equipment_path(id) {
    return `api/user_equipments/${id}/`
  },

  user_pumps_path() {
    return 'api/user_pumps/'
  },
  user_pump_path(id) {
    return `api/user_pumps/${id}/`
  },
  user_pumps_dd_list_path() {
    return `api/user_pumps/user_pumps_dd_list/`
  },
  user_pumps_list_path() {
    return `api/user_pumps/user_pumps_list/`
  },
  user_catalog_pumps_path() {
    return 'api/user_pumps/user_catalog_pumps_list/'
  },

  user_assets_path() {
    return 'api/user_pumps/'
  },
  user_pump_fields_customers_permissions_path() {
    return 'api/user_pump_fields_customer_visibility/'
  },
  user_pump_fields_customers_permissions_by_customer_id_path() {
    return 'api/user_pump_fields_customer_visibility/get_permission_by_customer_id/'
  },
  user_pump_fields_customer_permissions_path(id) {
    return `api/user_pump_fields_customer_visibility/${id}/`
  },
  user_pump_fields_users_permissions() {
    return 'api/user_pump_fields_user_visibility/'
  },
  user_pump_fields_user_permissions(id) {
    return `api/user_pump_fields_user_visibility/${id}/`
  },
  // user_asset_path(id) {
  //   return `api/user_assets/${id}/`
  // },
  export_user_equipments_path() {
    // Need to create this path
    return
  },
  user_locations_path() {
    return 'api/user_locations/'
  },
  user_location_path(id) {
    return `api/user_locations/${id}/`
  },
  user_location_ddl_path() {
    return `api/user_locations/user_location_ddl/`
  },
  user_drawings_path() {
    return 'api/fluid_end_parts/fluid_end_drawings_list_for_size_and_material/'
  },
  user_models_path() {
    return 'api/models/create_user_model/'
  },
  models_search_path() {
    return 'api/models_family_manufacturer_search/'
  },
  models_path() {
    return 'api/models/'
  },
  models_lib_path() {
    return 'api/models/library_ddl/'
  },
  vendors_path() {
    return 'api/vendors/'
  },
  vendor_path(id) {
    return `api/vendors/${id}/`
  },
  vendors_ddl_path() {
    return `api/vendors/vendors_dd_list/`
  },
  get_style_or_material_path() {
    return 'api/get_style_or_material/'
  }
  // user_drawing_path(id) {
  //   // Not in sheet
  // },
  // import_data_path(){
  //   return 'api/import_csv/'
  // }
}
