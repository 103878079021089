import Vue from 'vue'
import router from '../_main/router'
import auth from '../_main/auth'
import localData from '../mixins/localData'
import { authHeader } from '../_main/_auth-header'
const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value

function defaultData() {
  // console.log("auth ", auth);
  return {
    id: null,
    ref_id: null,
    asset_id: null,
    asset_name: '',
    status: 'cart',
    data: {
      full_name: _.get(auth, 'currentUser.full_name'),
      email_address: _.get(auth, 'currentUser.email'),
      company_name: _.get(auth, 'currentCustomer.name'),
      reference_number: '',
      billingLocation: {},
      shippingLocation: {},
      phone_number: '',
      description: '',
      deliveryInstructions: '',
      additional_info: '',
      email_list: [],
      is_vendor: false,
      vendor_list: [],
    },
    line_items: [],
    kits: [],
    pumps: [],
    attachments: [],
    admin_attachments: [],
    user_id: _.get(auth, 'currentUser.id'),
    customer_id: _.get(auth, 'currentCustomer.id'),
    user: _.get(auth, 'currentUser'),
    customer: _.get(auth, 'currentCustomer'),
    // is_saved:false,
    is_submitted: false,
    is_admin_replied: false,
    is_admin_saved: false,
    user_pump: {},
    user_equipment: {},
    user_equipment_id: 0,
    user_pump_id: 0,
  

  }
}

const cart = new Vue({
  name: 'cart',
  router: router,
  mixins: [localData],
  data() {
    return defaultData()
  },
  // watch: {
  //   customer_id: function() {
  //     this.cartCount()
  //   }
  // },
  computed: {
    isCart() {
      return this.status === 'cart'
    },
    isRFQ() {
      return this.status === 'rfq'
    },
    isOrder() {
      return this.status === 'order'
    },
    cartCount() {
      // return _.sumBy(this.line_items, line => {
      //   return _.toNumber(line.qty) +
      // })
      let line = _.sumBy(this.line_items, line => {
        return _.toNumber(line.qty)
      })
      let kit = _.sumBy(this.kits, line => {
        return _.toNumber(line.qty)
      })
      let pump = _.sumBy(this.pumps, line => {
        return _.toNumber(line.qty)
      })
      return line + kit + pump
      // return _.sumBy(this.line_items, 'qty')
    }
  },
  created() {
    this.ref_id = this.uuidv4()
    this.uid = this.numeric_uuid()
    this.data.full_name =
      _.get(auth, 'currentUser.first_name') +
      ' ' +
      _.get(auth, 'currentUser.last_name')
    this.data.email_address = _.get(auth, 'currentUser.email')
    this.data.company_name = _.get(auth, 'currentCustomer.name')
    this.user_id = _.get(auth, 'currentUser.id')
    this.customer_id = _.get(auth, 'currentCustomer.id')
    this.user = _.get(auth, 'currentUser')
    this.customer = _.get(auth, 'currentCustomer')
  },
  // mounted() {
  //   this.ref_id = this.uuidv4()
  // },
  methods: {
    lUUID() {
      var dt = new Date().getTime()
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function(c) {
          var r = (dt + Math.random() * 16) % 16 | 0
          dt = Math.floor(dt / 16)
          return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
        }
      )
      return uuid
    },
    numeric_uuid() {
      return Math.floor(Math.random() * 1e16)
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
    addItem(newLineItem) {
      // DWW: CALLED LOCALLY
      this.$emit('addItem', newLineItem)
      let index = _.findIndex(this.line_items, lineItem => {
        return lineItem.item.id === newLineItem.item.id
      })

      if (index >= 0) {
        this.line_items[index].qty++
        Vue.set(this.line_items, index, this.line_items[index])
      } else {
        this.line_items.push(newLineItem)
      }
      this.saveCartToDB()
    },
    addPump(pump) {
      // DWW: CALLED LOCALLY
      this.$emit('addPump', pump)
      let index = _.findIndex(this.pumps, obj => {
        return obj.item.id === pump.item.id
      })

      if (index >= 0) {
        this.pumps[index].qty++
        Vue.set(this.pumps, index, this.pumps[index])
      } else {
        if(!this.pumps) this.pumps=[]
        this.pumps.push(pump)
      }
      this.saveCartToDB()
    },
    addKitItem(newLineItem) {
      // DWW: CALLED LOCALLY
      this.$emit('addKitItem', newLineItem)
      //this.kits.push(newLineItem)

      let index = _.findIndex(this.kits, lineItem => {
        return lineItem.item.title === newLineItem.item.title
      })

      if (index >= 0) {
        this.kits[index].qty++
        Vue.set(this.kits, index, this.kits[index])
      } else {
        this.kits.push(newLineItem)
      }
      console.log('KITS ', this.kits)
      this.saveCartToDB()
    },
    addNewItem() {
      // DWW: CALLED FROM CART VIEW
      this.$emit('addNewITem')
      this.addItem({ qty: 1, item: {} })
    },
    addNewPump() {
      // DWW: CALLED FROM CART VIEW
      this.$emit('addNewPump')
      this.addPump({ qty: 1, item: {} })
    },
    addKitToCart(item) {
      // DWW: CALLED FROM SEVERAL VIEWS
      console.log('item ', item)
      // console.log('kit ', item)
      // console.log('parts ', parts)
      this.$emit('addKitToCart', item)
      //item.qty = item.qty == undefined ? 1 : item.qty

      this.addKitItem({ id: item.id, qty: 1, item: item })
      this.$buefy.snackbar.open({
        message: `${item.title} added to cart`,
        queue: false,
        // indefinite: true,
        actionText: 'View Cart',
        onAction: () => {
          this.$navigate.cart()
        }
      })
    },
    addCartItem(item) {
      // DWW: CALLED FROM SEVERAL VIEWS
      console.log('item ', item)
      this.$emit('addCartItem', item)
      item.qty = item.qty == undefined ? 1 : item.qty
      item.replacement = item.replacement? true : false
      // item.qty = item.itemType == "Part" ? 1 : item.qty;

      this.addItem({ qty: item.qty, item: item })
      this.$buefy.snackbar.open({
        message: `${
          item.title != undefined ? item.title : item.number
        } added to cart`,
        queue: false,
        // indefinite: true,
        actionText: 'View Cart',
        onAction: () => {
          this.$navigate.cart()
        }
      })
    },
    addPumpToCart(item) {
      // DWW: CALLED FROM SEVERAL VIEWS
      console.log('item ', item)
      // this.$emit('addCartItem', item)
      item.qty = item.qty == undefined ? 1 : item.qty
      // item.qty = item.itemType == "Part" ? 1 : item.qty;

      this.addPump({ qty: item.qty, item: item })
      this.$buefy.snackbar.open({
        message: `${
          item.title != undefined ? item.title : item.number
        } added to cart`,
        queue: false,
        // indefinite: true,
        actionText: 'View Cart',
        onAction: () => {
          this.$navigate.cart()
        }
      })
    },
    removeKitItemPart(item, part) {
      let targetKit = _.find(this.kits, lineItem => {
        return lineItem.id === item.id
      })

      let part_index = _.findIndex(targetKit.item.parts, lineItem => {
        return lineItem.id === part.id
      })
      targetKit.item.parts.splice(part_index, 1)
    },
    removeKitItem(item) {
      let index = _.findIndex(this.kits, lineItem => {
        return lineItem.item === item
      })
      this.kits.splice(index, 1)
      this.saveCartToDB()
    },
    removeCartItem(item) {
      let index = _.findIndex(this.line_items, lineItem => {
        return lineItem.item.id === item.id
      })
      this.line_items.splice(index, 1)
      this.saveCartToDB()
    },
    removePump(item) {
      let index = _.findIndex(this.pumps, pump => {
        return pump.item.id === item.id
      })
      this.pumps.splice(index, 1)
      this.saveCartToDB()
    },
    updateCartItem(lineItem, update) {
      // DWW: NOT USED
      Vue.set(this.line_items, _.indexOf(this.line_items, lineItem), update)
    },
    clearCart() {
      // DWW: CALLED LOCALLY
      _.assign(this, defaultData())
      this.$cart.attachments = [] // This is important to reset attachments
      this.$navigate.cart()
    },
    getHeaders() {
      return {
        headers: {
          ...authHeader(),
          'X-CSRFToken': csrftoken,
          'Content-Type':
            'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
        }
      }
    },
    getFormData(quote) {
      let formData = new FormData()
      let formDataArray = []

      formDataArray = _.cloneDeep(quote)
      // console.log(formDataArray)
      Object.entries(formDataArray).forEach(([key, value]) => {
        // if( key == 'attachments'){
        //   quote.attachments.forEach(function (attachment, index) {
        //     if(attachment.id)
        //   });
        //   for( var i = 0; i < quote.attachments.length; i++ ){

        //     let file = quote.attachments[i];
        //     console.log(file.name)
        //     formData.append('attachments[' + i + ']', file);
        //   }
        // }
        if (typeof value === 'object') {
          //-&& key!=='attachments'
          console.log('Type object', key)
          console.log('Type object-', typeof value)
          value = JSON.stringify(value)
          formData.append(key, value)
        } else {
          //-if(key!=='attachments')
          console.log('Type not object', key)
          console.log('Type not object-', typeof value)
          formData.append(key, value)
        }
      })
      return formData
    },
    submitQuote(quote) {
      console.log('here is quote', quote)
      quote.is_submitted = true
      quote.status = "submitted_for_quote"
      // if (quote.user_equipment_id == null) {
      //   quote.user_equipment_id = 0
      // }
      // if (quote.user_pump_id == null) {
      //   quote.user_pump_id = 0
      // }
      let formData = this.getFormData(quote)
      if (quote.id == null) {
        this.$http
          .post(
            this.$routes.customer_quotes_path(),
            formData,
            this.getHeaders()
          )
          .then(response => {
            var ref = _.get(response, 'data.quote.ref_id')
            if (ref) {
              this.clearCart()
              // this.$navigate.customerQuote({ id: ref })
              this.$router.push({ name: 'customerQuotes' })
            }
          })
      } else {
        this.$http
          .put(
            this.$routes.customer_quote_path(quote.id),
            formData,
            this.getHeaders()
          )
          .then(response => {
            var ref = _.get(response, 'data.quote.ref_id')
            if (ref) {
              this.clearCart()
              // this.$navigate.customerQuote({ id: ref })
              this.$router.push({ name: 'customerQuotes' })
            }
          })
      }
    },

    saveQuote(quote) {
      let formData = this.getFormData(quote)
      console.log('here is quote', quote)
      console.log('here is formData', formData)
      // if (quote.user_equipment_id == null) {
      //   quote.user_equipment_id = 0
      // }
      // if (quote.user_pump_id == null) {
      //   quote.user_pump_id = 0
      // }
      if (quote.id == null) {
        this.$http
          .post(
            this.$routes.customer_quotes_path(),
            formData,
            this.getHeaders()
          )
          .then(response => {
            this.inEditMode = false
            this.$buefy.toast.open('Record Saved!')
            if (this.$cart.isRFQ) {
              this.clearCart()
              this.$router.push({ name: 'customerQuotes' })
            }
          })
          .catch(error => {
            console.log('error --> ', error)
          })
      } else {
        console.log(formData, formData.user_equipment_id)

        this.$http
          .put(
            this.$routes.customer_quote_path(quote.id),
            formData,
            this.getHeaders()
          )
          .then(response => {
            this.inEditMode = false
            this.$buefy.toast.open('Record Saved!')
            if (this.$cart.isRFQ) {
              this.clearCart()
              this.$router.push({ name: 'customerQuotes' })
            }
          })
          .catch(error => {
            console.log('error --> ', error)
          })
      }
    },
    saveOrder(order) {
      if (order.status == 'order') {
        order.status = 'order_saved'
      }
      let formData = this.getFormData(order)
      console.log(order)
      if (order.id == null) {
        if (order.status == 'order') {
          formData.order.status = 'order_saved'
        }
        this.$http
          .post(
            this.$routes.customer_orders_path(),
            formData,
            this.getHeaders()
          )
          .then(response => {
            this.inEditMode = false
            this.$buefy.toast.open('Record Saved!')
            if (this.$cart.isOrder) {
              this.clearCart()
              console.log(this.$route.name)
              if (this.$route.name == 'customerOrder')
                this.$router.push({ name: 'customerOrders' })
              else if (this.$route.name == 'adminOrder')
                this.$router.push({ name: 'adminOrders' })
            }
          })
          .catch(error => {
            console.log('error --> ', error)
          })
      } else {
        this.$http
          .put(
            this.$routes.customer_order_path(order.id),
            formData,
            this.getHeaders()
          )
          .then(response => {
            this.inEditMode = false
            this.$buefy.toast.open('Record Saved!')
            if (this.$cart.isOrder) {
              this.clearCart()
              if (this.$route.name == 'customerOrder')
                this.$router.push({ name: 'customerOrders' })
              else if (this.$route.name == 'adminOrder')
                this.$router.push({ name: 'adminOrders' })
            }
          })
          .catch(error => {
            console.log('error --> ', error)
          })
      }
    },
    submitOrder(order) {
      order.is_submitted = true
      order.order_date = new Date()
      let formData = this.getFormData(order)
      if (order.id == null) {
        this.$http
          .post(
            this.$routes.customer_orders_path(),
            formData,
            this.getHeaders()
          )
          .then(response => {
            var ref = _.get(response, 'data.order.ref_id')

            if (ref) {
              this.clearCart()
              this.$buefy.toast.open('Order Submitted!')
              // this.$navigate.customerOrder({ id: ref })
              this.$router.push({ name: 'customerOrders' })
            }
          })
      } else {
        this.$http
          .put(
            this.$routes.customer_order_path(order.id),
            formData,
            this.getHeaders()
          )
          .then(response => {
            this.inEditMode = false
            this.$buefy.toast.open('Order Submitted!')
            if (this.$cart.isOrder) {
              this.clearCart()
              if (this.$route.name == 'customerOrder')
                this.$router.push({ name: 'customerOrders' })
              else if (this.$route.name == 'adminOrder')
                this.$router.push({ name: 'adminOrders' })
            }
          })
          .catch(error => {
            console.log('error --> ', error)
          })
      }
    },
    saveCartToDB() {
      let cartData = this.$cart._data
      cartData.customer_id = this.$auth.currentCustomer$.id
      let formData = this.getFormData(cartData)
      console.log('saveCartToDB - cartData', cartData)
      console.log('saveCartToDB - formData', formData)
      if (cartData.id == null) {
        this.$http
          .post(
            this.$routes.customer_quotes_path(),
            formData,
            this.getHeaders()
          )
          .then(response => {
            this.inEditMode = false
            this.$buefy.toast.open('Cart Saved!')
          })
          .catch(error => {
            console.log('error --> ', error)
          })
      } else {
        this.$http
          .put(
            this.$routes.customer_quote_path(cartData.id),
            formData,
            this.getHeaders()
          )
          .then(response => {
            this.inEditMode = false
            this.$buefy.toast.open('Cart Saved!')
          })
          .catch(error => {
            console.log('error --> ', error)
          })
      }
    },
    getCartFromDB(customer_id, user_id) {
      //get cart from database and update in local storage
      let cart_user_id = this.$auth.currentUser.id
      if (user_id) {
        cart_user_id = user_id
      }
      console.log('getCartFromDB', customer_id, cart_user_id)
      let query_params = {
        customer_id: customer_id,
        user_id: cart_user_id
      }
      this.$http
        .get(this.$routes.get_cart_data_for_customer_path(), {
          params: query_params
        })
        .then(response => {
          _.assign(this, defaultData())
          // console.log('get_cart_data_for_customer_path response', response.data)
          if (response.data?.id != 0) {
            localStorage.setItem('cart', JSON.stringify(response.data))
            _.assign(this, JSON.parse(localStorage.getItem('cart')))
          } else {
            localStorage.setItem('cart', JSON.stringify(defaultData()))
            _.assign(this, defaultData())
          }

          // this.$cart._data = response.data
        })
    }
  }
})

export default cart
