<template lang="pug">
  div#attachmentDisplayModal
    b-modal.attachmentDialog.align_to_top(
      :active.sync="$attachmentDisplayModal.display"
      :hasModalCard="true"
    )
      //- :canCancel="['escape', 'outside']"
      div.modal-card
        header.modal-card-head
          p.modal-card-title {{ $attachmentDisplayModal.attachment.name }}
        section.modal-card-body

            //- template(v-if="$attachmentDisplayModal.isFileTypePDF($attachmentDisplayModal.attachment.file)")

            //-   AppPDFViewer(:sourceURL="$attachmentDisplayModal.attachment.file")
            //- template(v-else)

            figure.image
              template(v-if="$attachmentDisplayModal.isFileImage($attachmentDisplayModal.attachment.file)")
                img(:src="$attachmentDisplayModal.attachment.file")

              template(v-else)
                img(:src="$attachmentDisplayModal.attachment.thumbnail")
        footer.modal-card-foot.has-content-justified
          div.level-left
            //- app-button.is-danger(v-if="$auth.is.ieqAdmin") Delete
            a.button.is-save(:href="$attachmentDisplayModal.attachment.file" target="_blank") Download
          div.level-right
            a.button.level-item(@click="$attachmentDisplayModal.previous" :disabled="!$attachmentDisplayModal.hasPreviousAttachment"): b-icon(icon="skip-previous")
            a.button.level-item(@click="$attachmentDisplayModal.next" :disabled="!$attachmentDisplayModal.hasNextAttachment"): b-icon(icon="skip-next")
</template>
<script>
import Vue from 'vue'
// import AppPDFViewer from '../components/AppPDFViewer.vue'

function defaultData() {
  return {
    display: false,
    attachments: [],
    attachment: {}
  }
}

Vue.prototype.$attachmentDisplayModal = new Vue({
  name: '$attachmentDisplayModal',
  // beforeCreate: function() {
  //   this.$options.components.AppPDFViewer = require('../components/AppPDFViewer.vue').default
  // },
  // components: {
  //   AppPDFViewer: () => import('../components/AppPDFViewer.vue')
  //   // AppPDFViewer
  // },
  data() {
    return {
      ...defaultData(),
      dataReady: false
    }
  },
  watch: {
    $attachmentDisplayModal: {
      handler(value) {
        if (value.attachment.length) {
          this.dataReady = true
        }
      },
      deep: true
    }
  },

  computed: {
    currentAttachmentIndex() {
      console.log('this.attachments ', this.attachments)
      return _.indexOf(this.attachments, this.attachment)
    },
    lastAttachmentIndex() {
      return this.attachments.length - 1
    },
    nextAttachmentIndex() {
      return this.currentAttachmentIndex == this.lastAttachmentIndex
        ? null
        : this.currentAttachmentIndex + 1
    },
    hasNextAttachment() {
      return !_.isNull(this.nextAttachmentIndex)
    },
    previousAttachmentIndex() {
      return this.currentAttachmentIndex == 0
        ? null
        : this.currentAttachmentIndex - 1
    },
    hasPreviousAttachment() {
      return !_.isNull(this.previousAttachmentIndex)
    }
  },
  methods: {
    show(attachment, attachments = []) {
      this.display = true
      this.attachments = attachments
      this.attachment = attachment
    },
    next() {
      if (this.hasNextAttachment) {
        this.attachment = this.attachments[this.nextAttachmentIndex]
      }
    },
    previous() {
      if (this.hasPreviousAttachment) {
        this.attachment = this.attachments[this.previousAttachmentIndex]
      }
    },
    getFileExtension(fileName) {
      var fileExtension = ''
      if (fileName) fileExtension = fileName.replace(/^.*\./, '')
      return fileExtension
    },
    isFileImage(fileName) {
      var fileExt = this.getFileExtension(fileName).toLowerCase()
      var imagesExtension = ['png', 'jpg', 'jpeg']
      if (imagesExtension.indexOf(fileExt) !== -1) {
        return true
      } else {
        return false
      }
    },
    isFileTypePDF(file) {
      let file_name = file
      if (file) {
        return (
          file_name
            .substring(file_name.lastIndexOf('.') + 1, file_name.length)
            .toUpperCase() == 'PDF'
        )
      } else return false
    }
  }
})

export default Vue.prototype.$attachmentDisplayModal
</script>
