<template lang="pug">
  #app
    template(v-if='$auth.isSignedIn')
      nav.navbar.is-fixed-top(v-if='$auth.isSignedIn')
        .navbar-brand
          a.navbar-burger(
            @click='showSidebarNav()',
            :class='{ "is-active": !showNavOnMobile }'
          )
            span
            span
            span
          a.navbar-item.toggle-sidebar.is-desktop-icon-only.is-hidden-mobile(
            @click='showSidebarNav()',
            :class='{ "is-active": !showNavOnDesktop }'
          )
            b-icon(icon='backburger')
            //-backburger
          router-link.navbar-item.is-paddingless(
            :to='{ name: "root" }'
          )
            span(v-show='!$theme.darkMode').brand-logo
              img(:src='require("./images/TENAN-logo-mobile.png")' style="max-width:120px" ).is-hidden-desktop
              img(:src='require("./images/TENAN-logo.png")'  ).is-hidden-mobile
            span(v-show='$theme.darkMode')
              img(:src='require("./images/TENAN-logo-white-mobile.png")'  style="max-width:120px").is-hidden-desktop
              img(:src='require("./images/TENAN-logo-white.png")' ).is-hidden-mobile

          template(v-if="!($auth.currentCustomer.id==0)")
            //- img.image(v-if="$auth.currentCustomer.logoUrl" :src="$auth.currentCustomer.logoUrl" :style="{height:'25px', marginRight: '0.1875rem'}")
            span.is-flex.is-align-content-center.is-align-items-center(height="100%").brand-logo.is-hidden-desktop
              img.image(  v-if="$auth.currentCustomer.logoUrl"
                        :src='$auth.currentCustomer.logoUrl',
                        :style='{ height: "40px", maxWidth: "40px"}'
                        class="mx-3"
                      )

          a.navbar-item.toggle-topnav.is-hidden-desktop.is-hidden-tablet-only(
            @click='showSearchBar()',
            :class='{ "is-active": showSearchOnMobile }'
          )
            b-icon(icon='magnify')
          a.navbar-item.toggle-topnav.is-hidden-desktop.is-hidden-tablet-only(
            @click='showTopNavOnMobile = !showTopNavOnMobile',
            :class='{ "is-active": showTopNavOnMobile }'
          )
            b-icon(icon='dots-vertical')

        b-field.header-search.is-hidden-touch.is-hidden-tablet-only
          vue-autosuggest.search(
            v-model='searchText',
            @keyup.enter="getSearchResult(searchText)"
            :suggestions='searchDataList',
            @focus='focusMe',
            @input='onInputChange',
            @selected='onSelected',
            :section-configs='sectionConfigs',
            :get-suggestion-value='getSuggestionValue',
            :input-props='inputProps'
            icon="magnify"
            style="position:relative; float: right; width: 300px;"
          )
            template( slot="before-input")
              b-icon(icon="magnify" style="position: absolute; top: 12px; left: 5px;")
            template(slot='before-suggestions')
              p(v-show='searchDataLoading', style='text-align: center;') Loading...
            template(slot-scope='{suggestion, index, cs}')
              div {{ suggestion && suggestion.item.text }}
            template(slot='after-suggestions')
              //- p(v-show='searchResultCount == 0', style='text-align: center;' ) No Results...
        template(v-if="!($auth.currentCustomer.id==0)")
            //- img.image(v-if="$auth.currentCustomer.logoUrl" :src="$auth.currentCustomer.logoUrl" :style="{height:'25px', marginRight: '0.1875rem'}")
            span.is-flex.is-align-content-center.is-align-items-center(height="100%").is-hidden-mobile
              img.image(  v-if="$auth.currentCustomer.logoUrl"
                        :src='$auth.currentCustomer.logoUrl',
                        :style='{ height: "40px", maxWidth: "40px"}'
                        class="mx-3"
                      )

        .navbar-menu.is-hidden-desktop(
          :class='{ showSearchOnMobile: showSearchOnMobile }',
          v-if='$auth.isSignedIn'
        )
          .navbar-item
            b-field.header-search
              vue-autosuggest.search(
                v-model='searchText',
                @keyup.enter="getSearchResult(searchText)"
                :suggestions='searchDataList',
                @focus='focusMe',
                @input='onInputChange',
                @selected='onSelected',
                :section-configs='sectionConfigs',
                :get-suggestion-value='getSuggestionValue',
                :input-props='inputProps'
              )
                template(slot='before-suggestions')
                  p(v-show='searchDataLoading', style='text-align: center;') Loading...
                template(slot-scope='{suggestion, index, cs}')
                  div {{ suggestion && suggestion.item.text }}
                template(slot='after-suggestions')
              //- b-input.search(placeholder="Search Assets and Locations" expanded rounded icon="magnify")

        .navbar-menu(
          :class='{ showTopNavOnMobile: showTopNavOnMobile }',
          v-if='$auth.isSignedIn'
        )
          .navbar-end
            .navbar-item(v-if='$auth.can.order')
              router-link.button.fav(:to='{ name: "cart" }')
                b-icon.badge.has-badge-black.has-badge-left.has-badge-outlined.has-badge-rounded(
                  icon='cart',
                  :data-badge='$cart.cartCount'
                )
                //- span.is-hidden-touch Cart
            //- .navbar-item.is-hidden-touch(v-if='$auth.isSignedIn')
            //-   button.button.fav(
            //-     @click='$router.push({ name: "userDashboard" })'
            //-   )
            //-     b-icon.is-marginless(
            //-       v-if='!$auth.currentCustomer.logoUrl',
            //-       icon='office-building'
            //-     )
            //-     img.image(
            //-       v-if='$auth.currentCustomer.logoUrl',
            //-       :src='$auth.currentCustomer.logoUrl',
            //-       :style='{ height: "1rem", marginRight: "0.1875rem" }'
            //-     )
                //- span.is-hidden-touch {{ $auth.currentCustomer.name }}
            .navbar-item(title="My Jobs")
              router-link.button.fav(:to='{ name: "myUserPumpJobs" }')
                b-icon(icon='clipboard-list')
            .navbar-item
              router-link.button.fav(:to='{ name: "contact" }')
                b-icon(icon='forum')
                //- span.is-hidden-touch Contact IEQ
            b-dropdown.navbar-item(
              v-if='$auth.isSignedIn',
              position='is-bottom-left',
              :mobile-modal='false'
            )
              button.button.fav(slot='trigger')
                b-icon(icon='account-circle')
                //- span.is-hidden-touch {{ $auth.currentUser.name || $auth.currentUser.email }}
                //- b-icon(icon='chevron-right')

              b-dropdown-item(@click='$auth.logOut')
                b-icon(icon='logout')
                span Log Out

              b-dropdown-item(@click='$router.push({ name: "profile" })')
                b-icon(icon='account')
                span Profile

              b-dropdown-item(@click='$router.push({ name: "change_password" })')
                b-icon(icon='account-key')
                span Change Password

              b-dropdown-item(v-if='($auth.is.ieqAdmin || $auth.is.admin)' @click="$router.push({ name: 'pump_fields_customer_permissions', params: { id: $auth.currentCustomer.user_pump_customer_visibility === null ? 'new' : $auth.currentCustomer.user_pump_customer_visibility.id } })")
                b-icon(icon='cog')
                span Admin - Customer Account Permissions
              //- b-dropdown-item(
              //-   @click="$router.push({ name: 'pump_fields_user_permissions', params: { id: $auth.user_pump_cxi_user_visibility === null ? 'new' : $auth.user_pump_cxi_user_visibility.id, user_self:true } })")
              //-   b-icon(icon='cog')
              //-   span Visibility Settings
              b-dropdown-item(v-if="$auth.is.ieqAdmin || $auth.is.admin" @click="$router.push({ name: 'pump_fields_users_permissions' })")
                b-icon(icon='cog')
                span Admin - Users Visibility Settings
              //- b-dropdown-item(@click='$router.push({ name: "import" })')
              //-   b-icon(icon='download-box')
              //-   span Import
              //- b-dropdown-item(@click='customUserDialogOpen()'
              //-       size="is-small")
              //-   b-icon(icon="tune")
              //-   span Users Dashboard Settings
              .dropdown-item(v-if='$auth.is.ieqAdmin')
                b-checkbox(v-model='$features.catalog') Catalog?

              .dropdown-item(v-if='$auth.is.ieqAdmin')
                b-checkbox(v-model='$features.library') Library?

              .dropdown-item(v-if='$auth.is.ieqAdmin')
                b-checkbox(v-model='$features.ordering') Orders?

              .dropdown-item(v-if='$auth.is.ieqAdmin')
                b-checkbox(v-model='$features.maintenance') Maintenance?
                //- For dark theme
              .dropdown-item
                b-field.theme-switch-wrap(size='is-small')
                  b-switch#theme-switch.theme-switch(
                    v-model='$theme.darkMode',
                    type='is-primary'
                  ) Switch to Dark Theme
                //- end for dark theme

            .navbar-item(v-if='!$auth.isSignedIn')
              .buttons
                app-button(
                  icon='login-variant',
                  @click='$router.push({ name: "login" })'
                ) Log In


      aside.menu.is-narrow.sidebar(
        :class='[{ "is-active": showMainSidebar }]',
        v-if='$auth.isSignedIn'
      )
        a.navbar-item.toggle-sidebar.is-desktop-icon-only.is-hidden-touch(
          @click='showSidebarNav()',
          :class='{ "is-active": !showNavOnDesktop }'
        )
          b-icon(icon='menu')
        perfect-scrollbar
          .account(v-if='$auth.can.viewLibrary')
            b-collapse( :open="true" aria-id="contentIdForLibrary")
              template( #trigger="props")

                  div.menu-label( aria-controls="contentIdForLibrary"
                      :aria-expanded="props.open")
                    span
                      b-icon(icon="bookshelf")
                      span.menu-label-text Library
                    b-icon( :icon="!props.open ? 'chevron-right' : 'chevron-down'")

              div.menu-content
                ul.menu-list
                  //- li(v-if='$auth.currentCustomer.logoUrl')
                  //-   router-link.is-paddingless.logo(:to='{ name: "userDashboard" }')
                  //-     img.image(
                  //-       :src='$auth.currentCustomer.logoUrl',
                  //-       :style='{ height: "75px", maxWidth: "70px"}'
                  //-     )

                  li: router-link(
                    :to='{ name: "userDashboard" }',
                    :class='{ "is-active": $router.currentRoute.name == "userDashboard" || $router.currentRoute.name == "root" }'
                  )
                    b-icon(icon='home')
                    span Home
                  li: router-link(
                    :to='{ name: "myDashboard" }',
                    :class='{ "is-active": $router.currentRoute.name == "myDashboard"}'
                  )
                    b-icon(icon='account-clock')
                    span My Work
                  //- li: router-link(
                  //-   :to='{ name: "userAssets" }',
                  //-   :class='{ "is-active": $router.currentRoute.name == "userAssets" || $router.currentRoute.name == "userAsset" }'
                  //- )
                  //-   b-icon(icon='folder-table')
                  //-   span Assets
                  li(@mouseover="showImg()" @mouseleave="showImg2()"): router-link(
                    :to='{ name: "userPumps" }',
                    :class='{ "is-active": $router.currentRoute.name == "userPumps" || $router.currentRoute.name == "userPump" }'
                  )
                    b-icon(icon='engine-outline')
                    //- img.ml-2(class="img1" :src='require("./images/ICON.svg")' alt="pump" style="display:none; max-width:30px; max-height:30px")
                    //- img.ml-2(class="img2" :src='require("./images/ICON2.svg")' alt="pump" style="max-width:30px; max-height:30px")
                    span.ml-2 Pumps

                  //- li: router-link(
                  //-   :to='{ name: "import" }',
                  //-   :class='{ "is-active": $router.currentRoute.name == "import" }'
                  //- )

                  //-   b-icon(icon='download-box')
                  //-   span Import

                  li: router-link(
                    :to='{ name: "userEquipments" }',
                    :class='{ "is-active": $router.currentRoute.name == "userEquipments" || $router.currentRoute.name == "userEquipment" }'
                  )
                    b-icon(icon='folder-table')
                    span Equipment

                  li: router-link(
                    :to='{ name: "userLocations" }',
                    :class='{ "is-active": $router.currentRoute.name == "userLocations" || $router.currentRoute.name == "location" }'
                  )
                    b-icon(icon='map-marker-radius')
                    span Locations



                  //- li: router-link(
                  //-   :to='{ name: "userParts" }',
                  //-   :class='{ "is-active": $router.currentRoute.name == "userParts" || $router.currentRoute.name == "userPart" }'
                  //- )
                  //-   b-icon(icon='pipe-disconnected')
                  //-   span Parts

                  //- li: router-link(
                  //-   :to='{ name: "replacement_parts" }',
                  //-   :class='{ "is-active": $router.currentRoute.name == "replacement_parts" || $router.currentRoute.name == "replacement_part" }'
                  //- )
                  //-   b-icon(icon='cog-sync')
                  //-   span Replacement Parts


                  li: router-link(
                    :to='{ name: "recommended_kits" }',
                    :class='{ "is-active": $router.currentRoute.name == "recommended_kits"}'
                  )
                    b-icon(icon='briefcase-check')
                    span Kits
                  //- li: router-link(
                  //-   :to={'name': "demo"},
                  //-   :class='{ "is-active": $router.currentRoute.name == "demo"}'
                  //- )
                  //-   b-icon(icon="file-document-edit")
                  //-   span Assembly Instructions
                  //- Commented on request of client per -  https://app.clubhouse.io/ieq/story/1149/cxi-new-app-changes
                  //- li(v-if='$auth.can.order')
                  //-   router-link(
                  //-     :to='{ name: "customerQuotes" }',
                  //-     :class='{ "is-active": $router.currentRoute.name == "customerQuotes" || $router.currentRoute.name == "customerQuote" }'
                  //-   )
                  //-     b-icon(icon='book-open')
                  //-     span Quotes
                  //- li(v-if='$auth.can.order')
                  //-   router-link(
                  //-     :to='{ name: "customerOrders" }',
                  //-     :class='{ "is-active": $router.currentRoute.name == "customerOrders" || $router.currentRoute.name == "customerOrder" }'
                  //-   )
                  //-     b-icon(icon='truck')
                  //-     span Orders

                  //- li(v-if='$auth.can.order')
                  //-   router-link(
                  //-     :to='{ name: "adminPartsOrders" }',
                  //-     :class='{ "is-active": $router.currentRoute.name == "adminPartsOrders" || $router.currentRoute.name == "adminPartsOrder" }'            )
                  //-       b-icon(icon='book-open')
                  //-       span Parts Orders

                  //- li(v-if='$auth.can.order')
                  //-   router-link(
                  //-     :to='{ name: "cart" }',
                  //-     :class='{ "is-active": $router.currentRoute.name == "cart" || $router.currentRoute.name == "rfq" }'
                  //-   )
                  //-     b-icon.badge.has-badge-black.has-badge-outlined.is-badge-small.has-badge-rounded(
                  //-       icon='cart',
                  //-       :data-badge='$cart.cartCount'
                  //-     )
                  //-     span Cart
                  b-menu
                    b-menu-list
                      b-menu-item.menu.more_menu_library.sub_menu( )
                        template( #label="props")
                          div.sub_menu_label
                            b-icon(icon="hammer-wrench" )
                            span Inventory
                          b-icon( class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'")
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='office-building-cog'
                          label="Inventory Warehouse"
                          tag="router-link"
                          :to='{ name: "inventory_list" }',
                          :class='{ "is-active": $router.currentRoute.name == "inventory_list" }'
                        )
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='cog-sync'
                          label="Inventory Replacement"
                          tag="router-link"
                          :to='{ name: "userPumpJobs" }',
                          :class='{ "is-active": $router.currentRoute.name == "userPumpJobs" }'
                        )
                        b-menu-item(
                          icon='note-text'
                          label="Job Notes"
                          tag="router-link"
                          :to='{ name: "userPumpJobNotes" }',
                          :class='{ "is-active": $router.currentRoute.name == "userPumpJobNotes" }'
                        )
                        b-menu-item(
                          icon='clipboard-list'
                          label="My Jobs"
                          tag="router-link"
                          :to='{ name: "myUserPumpJobs" }',
                          :class='{ "is-active": $router.currentRoute.name == "myUserPumpJobs" }'
                        )
                  b-menu
                    b-menu-list
                      b-menu-item.menu.more_menu_library.sub_menu( )
                        template( #label="props")
                          div.sub_menu_label
                            b-icon(icon="dots-horizontal-circle" )
                            span More
                          b-icon( class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'")
                        b-menu-item(
                            v-if='$auth.can.order'
                            icon='book-open'
                            label="Quotes"
                            tag="router-link"
                            :to='{ name: "customerQuotes" }'
                            :class='{ "is-active": $router.currentRoute.name == "customerQuotes" || $router.currentRoute.name == "customerQuote" }')
                        //- b-menu-item(
                        //-     v-if='$auth.can.order'
                        //-     icon='truck'
                        //-     label="Orders"
                        //-     tag="router-link"
                        //-     :to='{ name: "customerOrders" }',
                        //-     :class='{ "is-active": $router.currentRoute.name == "customerOrders" || $router.currentRoute.name == "customerOrder" }'
                        //-   )
                        b-menu-item(
                            v-if='$auth.can.order'
                            icon='cart-arrow-right'
                            label="Orders"
                            tag="router-link"
                            :to='{ name: "partsOrders" }',
                            :class='{ "is-active": $router.currentRoute.name == "partsOrders" || $router.currentRoute.name == "partsOrders" }'            )
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='calculator'
                          label="Calculator"
                          tag="router-link"
                          :to='{ name: "calculator" }',
                          :class='{ "is-active": $router.currentRoute.name == "calculator" }'
                        )
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='download-box'
                          label="Import"
                          tag="router-link"
                          :to='{ name: "import" }',
                          :class='{ "is-active": $router.currentRoute.name == "import" }'
                        )

                        b-menu-item(
                            v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                            icon='cog'
                            label="Settings"
                            tag="router-link"
                            :to='{ name: "settings" }',
                            :class='{ "is-active": $router.currentRoute.name == "settings"}'
                        )
                      //- b-menu-item.menu.more_menu_library.sub_menu( )
                      //-   template( #label="props")
                      //-     div.sub_menu_label
                      //-       b-icon(icon="cog" )
                      //-       span Settings
                      //-     b-icon( class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'")
                        b-menu-item(
                            v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                            icon='account-group'
                            label="Teams"
                            tag="router-link"
                            :to='{ name: "customerTeams" }',
                            :class='{ "is-active": $router.currentRoute.name == "customerTeams" || $router.currentRoute.name == "customerTeams" }'
                        )
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='map-marker-multiple'
                          label="Regions"
                          tag="router-link"
                          :to='{ name: "customerRegions" }',
                          :class='{ "is-active": $router.currentRoute.name == "customerRegions" || $router.currentRoute.name == "customerRegions" }'
                        )
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='store'
                          label="Vendors"
                          tag="router-link"
                          :to='{ name: "vendors" }',
                          :class='{ "is-active": $router.currentRoute.name == "vendors" || $router.currentRoute.name == "vendor" }'
                        )
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='shape'
                          label="Asset Category"
                          tag="router-link"
                          :to='{ name: "categories" }',
                          :class='{ "is-active": $router.currentRoute.name == "categories" || $router.currentRoute.name == "category" }'
                        )
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='hammer-wrench'
                          label="Job Types"
                          tag="router-link"
                          :to='{ name: "job_types" }',
                          :class='{ "is-active": $router.currentRoute.name == "job_types" || $router.currentRoute.name == "job_type" }'
                        )
                        b-menu-item(
                          v-if='($auth.is.ieqAdmin || $auth.is.admin)'
                          icon='tag'
                          label="Part Tag"
                          tag="router-link"
                          :to='{ name: "part_tags" }',
                          :class='{ "is-active": $router.currentRoute.name == "part_tags" || $router.currentRoute.name == "part_tag" }'
                        )


            template(v-if='($auth.customerFeatures && $auth.customerFeatures.catalog_detail) || $auth.can.viewCatalog')
              b-collapse( :open="true" aria-id="contentIdForCatalog")
                template( #trigger="props")

                    div.menu-label( aria-controls="contentIdForCatalog"
                        :aria-expanded="props.open")
                      span
                        b-icon(icon="library")
                        span.menu-label-text Catalog
                      b-icon( :icon="!props.open ? 'chevron-right' : 'chevron-down'")

                ul.menu-list.catalog
                  //- magnify-expand
                  //- li: router-link(
                  //-   :to='{ name: "pumpsSearch" }',
                  //-   :class='{ "is-active": $router.currentRoute.name == "pumpsSearch" }'
                  //- )
                  //-   b-icon(icon='magnify-scan')
                  //-   span Pump Search
                  li: router-link(
                    :to='{ name: "pumps" }',
                    :class='{ "is-active": $router.currentRoute.name == "pumps" || $router.currentRoute.name == "pump" }'
                  )
                    b-icon(icon='engine-outline')
                    span Pumps
                  li: router-link(
                    :to='{ name: "partsSearchLanding" }',
                    :class='{ "is-active": $router.currentRoute.name == "partsSearchLanding" }'
                  )
                    b-icon(icon='text-search')
                    span Parts Search


                  li: router-link(
                    :to='{ name: "parts" }',
                    :class='{ "is-active": $router.currentRoute.name == "parts" || $router.currentRoute.name == "part" }'
                  )
                    b-icon(icon='folder-cog')
                    span Parts Catalog

                  li: router-link(
                    :to='{ name: "drawings" }',
                    :class='{ "is-active": $router.currentRoute.name == "drawings" || $router.currentRoute.name == "drawing" }'
                  )
                    b-icon(icon='nfc-search-variant')
                    span Drawings
                  //- li: router-link(
                  //-   :to='{ name: "kits" }',
                  //-   :class='{ "is-active": $router.currentRoute.name == "kits" || $router.currentRoute.name == "kit" }'
                  //- )
                  //-   b-icon(icon='briefcase-check')
                  //-   span Kits

                  //- Commented on request of client per -  https://app.clubhouse.io/ieq/story/1149/cxi-new-app-changes
                  //- li: router-link(
                  //-   :to='{ name: "equipments" }',
                  //-   :class='{ "is-active": $router.currentRoute.name == "equipments" || $router.currentRoute.name == "equipments" }'
                  //- )
                  //-   b-icon(icon='folder-table')
                  //-   span Equipment



            template(v-if='$auth.is.ieqAdmin')
              b-collapse( :open="true" aria-id="contentIdForAdmin")
                template( #trigger="props")

                    div.menu-label( aria-controls="contentIdForAdmin"
                        :aria-expanded="props.open")
                      span
                        b-icon(icon="shield-account")
                        span.menu-label-text Admin
                      b-icon( :icon="!props.open ? 'chevron-right' : 'chevron-down'")

                ul.menu-list
                  li: router-link(
                    :to='{ name: "customers" }',
                    :class='{ "is-active": $router.currentRoute.name == "customers" || $router.currentRoute.name == "customer" }'
                  )
                    b-icon(icon='account-star')
                    span Customers
                  //- mdi-account-star
                  li: router-link(
                    :to='{ name: "users" }',
                    :class='{ "is-active": $router.currentRoute.name == "users" || $router.currentRoute.name == "user" }'
                  )
                    b-icon(icon='account-multiple')
                    span Users
                  //- mdi-account-multiple
                  li: router-link(
                    :to='{ name: "manufacturers" }',
                    :class='{ "is-active": $router.currentRoute.name == "manufacturers" || $router.currentRoute.name == "manufacturer" }'
                  )
                    b-icon(icon='factory')
                    span Manufacturers

                  li: router-link(
                    :to="{name: 'assetTags'}"
                    :class="{'is-active': $router.currentRoute.name == 'assetTags' || $router.currentRoute.name == 'assetTags'}"
                  )
                    b-icon(icon="barcode")
                    span Asset Tags
                  li: router-link(
                    :to='{ name: "fluid_ends" }',
                    :class='{ "is-active": $router.currentRoute.name == "fluid_ends" || $router.currentRoute.name == "fluid_end" }'
                  )
                    b-icon(icon='water-pump')
                    span Fluid End
                  li: router-link(
                    :to='{ name: "power_ends" }',
                    :class='{ "is-active": $router.currentRoute.name == "power_ends" || $router.currentRoute.name == "power_end" }'
                  )
                    b-icon(icon='power-plug')
                    span Power End
                  li: router-link(
                      v-if='$auth.can.order'
                      :to='{ name: "adminQuotes" }'
                      :class='{ "is-active": $router.currentRoute.name == "adminQuotes" || $router.currentRoute.name == "adminQuote" }'
                  )
                      b-icon(icon='book-open')
                      span Quotes

                  li: router-link(
                      v-if='$auth.can.order'
                      :to='{ name: "adminPartsOrders" }',
                      :class='{ "is-active": $router.currentRoute.name == "adminPartsOrders" || $router.currentRoute.name == "adminPartsOrder" }'
                  )
                      b-icon(icon='cart-arrow-right')
                      span Orders

                  li: router-link(
                      :to='{ name: "email_templates" }',
                      :class='{ "is-active": $router.currentRoute.name == "email_templates"}'
                  )
                      b-icon(icon='email-outline')
                      span Email Template
                  li: router-link(
                      :to='{ name: "maintenance_schedules" }',
                      :class='{ "is-active": $router.currentRoute.name == "maintenance_schedules"}'
                  )
                      b-icon(icon='wrench-clock')
                      span Maintenance
                  li: router-link(
                      :to='{ name: "troubleshooting_info" }',
                      :class='{ "is-active": $router.currentRoute.name == "troubleshooting_info"}'
                  )
                      b-icon(icon='folder-wrench-outline')
                      span Troubleshooting
                  li: router-link(
                      :to='{ name: "torque_specifications" }',
                      :class='{ "is-active": $router.currentRoute.name == "torque_specifications"}'
                  )
                      b-icon(icon='file-cog-outline')
                      span Torque Specs
                  li: router-link(
                      :to='{ name: "pump_specifications" }',
                      :class='{ "is-active": $router.currentRoute.name == "pump_specifications" || $router.currentRoute.name == "pump_specification" }'
                  )
                      b-icon(icon='file-cog-outline')
                      span Pump Specs
                  li: router-link(
                      :to='{ name: "operating_instructions" }',
                      :class='{ "is-active": $router.currentRoute.name == "operating_instructions"}'
                  )
                      b-icon(icon='file-document-multiple-outline')
                      span Operating Instructions
                  li: router-link(
                      :to='{ name: "search_classification" }',
                      :class='{ "is-active": $router.currentRoute.name == "search_classification"}'
                  )
                      b-icon(icon='tag-search')
                      span Search Classification
                  li: router-link(
                      :to='{ name: "userPumpJobsAdmin" }',
                      :class='{ "is-active": $router.currentRoute.name == "userPumpJobsAdmin"}'
                  )
                      b-icon(icon='cog-sync')
                      span Inventory Replacement

            template(v-if='$auth.is.ieqAdmin')
              b-collapse( :open="true" aria-id="contentIdForSettings")
                template( #trigger="props")

                    div.menu-label( aria-controls="contentIdForSettings"
                        :aria-expanded="props.open")
                      span
                        b-icon(icon='store-cog')
                        span.menu-label-text Settings
                      b-icon( :icon="!props.open ? 'chevron-right' : 'chevron-down'")

                ul.menu-list.admin-menu

                  li: router-link(
                    :to='{ name: "interchangeable_parts" }',
                    :class='{ "is-active": $router.currentRoute.name == "interchangeable_parts" || $router.currentRoute.name == "interchangeable_part" }'
                  )
                    b-icon(icon='swap-horizontal-bold')
                    span Interchangeable Parts


                  li
                      router-link(
                        :to='{ name: "measurement_units" }',
                        :class='{ "is-active": $router.currentRoute.name == "measurement_unit" || $router.currentRoute.name == "measurement_units" }'
                      )
                        b-icon(icon='scale-balance')
                        span Measurement Units
                  li
                      router-link(
                        :to='{ name: "sizes" }',
                        :class='{ "is-active": $router.currentRoute.name == "size" || $router.currentRoute.name == "sizes" }'
                      )
                        b-icon(icon='move-resize')
                        span Part Sizes
                  li
                      router-link(
                        :to='{ name: "material_types" }',
                        :class='{ "is-active": $router.currentRoute.name == "material_type" || $router.currentRoute.name == "material_types" }'
                      )
                        b-icon(icon='layers')
                        span Material Types
                  li
                      router-link(
                        :to='{ name: "styles" }',
                        :class='{ "is-active": $router.currentRoute.name == "style" || $router.currentRoute.name == "styles" }'
                      )
                        b-icon(icon='transition-masked')
                        span Styles

                  li
                      router-link(
                        :to='{ name: "application_types" }',
                        :class='{ "is-active": $router.currentRoute.name == "application_type" || $router.currentRoute.name == "application_types" }'
                      )
                        b-icon(icon='oil')
                        span Application
                        span Types

                  li
                      router-link(
                        :to='{ name: "part_classifications" }',
                        :class='{ "is-active": $router.currentRoute.name == "part_classification" || $router.currentRoute.name == "part_classifications" }'
                      )
                        b-icon(icon='shape')
                        span Part
                        span Classification

                  li
                      router-link(
                        :to='{ name: "assembly_types" }',
                        :class='{ "is-active": $router.currentRoute.name == "assembly_type" || $router.currentRoute.name == "assembly_types" }'
                      )
                        b-icon(icon='package-variant')
                        span Assembly
                        span Types
                  li
                      router-link(
                        :to='{ name: "assembly_classifications" }',
                        :class='{ "is-active": $router.currentRoute.name == "assembly_classification" || $router.currentRoute.name == "assembly_classifications" }'
                      )
                        b-icon(icon='package-variant')
                        span Assembly
                        span Classification
                  //- li
                  //-     router-link(
                  //-       :to='{ name: "connection_types" }',
                  //-       :class='{ "is-active": $router.currentRoute.name == "connection_type" || $router.currentRoute.name == "connection_types" }'
                  //-     )
                  //-       b-icon(icon='vector-link')
                  //-       span Connection
                  //-       span Types
                  li
                      router-link(
                        :to='{ name: "fluid_end_connection_types" }',
                        :class='{ "is-active": $router.currentRoute.name == "fluid_end_connection_type" || $router.currentRoute.name == "fluid_end_connection_types" }'
                      )
                        b-icon(icon='vector-link')
                        span Connection
                        span Types
                  li
                      router-link(
                        :to='{ name: "packing_types" }',
                        :class='{ "is-active": $router.currentRoute.name == "packing_type" || $router.currentRoute.name == "packing_types" }'
                      )
                        b-icon(icon='bolt')
                        span Packing Types
                  li
                      router-link(
                        :to='{ name: "casing_sizes" }',
                        :class='{ "is-active": $router.currentRoute.name == "casing_sizes"  || $router.currentRoute.name == "casing_size"}'
                      )
                        b-icon(icon='move-resize')
                        span Casing Sizes

                  li
                      router-link(
                        :to='{ name: "subscriptions" }',
                        :class='{ "is-active": $router.currentRoute.name == "subscriptions"  || $router.currentRoute.name == "subscription"}'
                      )
                        b-icon(icon='script-text-key')
                        span Subscriptions

                  //- li
                  //-   router-link(:to='{name: "pump_fields_customers_permissions"}' :class='{ "is-active": $router.currentRoute.name == "pump_fields_customers_permissions"  || $router.currentRoute.name == "pump_fields_customers_permissions"}')
                  //-     b-icon(icon='cog')
                  //-     span Pump Field Permissions
                  //- li
                  //-     router-link(
                  //-       :to='{name: "pump_fields_customer_permissions"}'
                  //-       :class='{ "is-active": $router.currentRoute.name == "pump_fields_customer_permissions"  || $router.currentRoute.name == "pump_fields_customer_permissions"}'
                  //-     )
                  //-       b-icon(icon='cog')
                  //-       span Pump Field Permissions

                  //- li(v-if='$auth.can.order')
                  //-   b-menu-item( icon="dots-horizontal-circle" label="More" v-popover:more_menu.right)

                      //b-icon(icon='dots-horizontal-circle')
                      //span More

                        //- li(v-if='$auth.can.order')
                        //-   router-link(
                        //-     :to='{ name: "adminQuotes" }',
                        //-     :class='{ "is-active": $router.currentRoute.name == "adminQuotes" || $router.currentRoute.name == "adminQuote" }'
                        //-   )
                          //- b-icon(icon='book-open')
                          //- span Quotes
                  //- li: router-link(:to="{name: 'countries'}") Country/State/City
                  //- li: a(href='/Tenan-data')
                  //-   b-icon(icon='database-plus')
                  //-   span Tenan Data
                  //- mdi-database-plus
                //- popover(name="more_menu")
                  b-collapse(
                    class="card"
                    animation="slide"
                    aria-id="contentIdForA11y3")
                    template(#trigger="props")
                      div(
                        class="card-header"
                        role="button"
                        aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open")
                        p.card-header-title More
                        a.card-header-icon
                          b-icon(:icon="props.open ? 'chevron-right' : 'chevron-down'")

                      div.card-content
                        div.content

                          ul.menu
                            b-menu-item(
                                v-if='$auth.can.order'
                                icon='book-open'
                                label="Quotes"
                                tag="router-link"
                                :to='{ name: "adminQuotes" }'
                                :class='{ "is-active": $router.currentRoute.name == "adminQuotes" || $router.currentRoute.name == "adminQuote" }')
                            //- b-menu-item(
                            //-     v-if='$auth.can.order'
                            //-     icon='truck'
                            //-     label="Orders"
                            //-     tag="router-link"
                            //-     :to='{ name: "adminOrders" }',
                            //-     :class='{ "is-active": $router.currentRoute.name == "adminOrders" || $router.currentRoute.name == "adminOrder" }'
                            //-   )
                            b-menu-item(
                                v-if='$auth.can.order'
                                icon='book-open'
                                label="Orders"
                                tag="router-link"
                                :to='{ name: "adminPartsOrders" }',
                                :class='{ "is-active": $router.currentRoute.name == "adminPartsOrders" || $router.currentRoute.name == "adminPartsOrder" }'            )

                            b-menu-item(
                                icon='pencil'
                                label="Email Template"
                                tag="router-link"
                                :to='{ name: "email_templates" }',
                                :class='{ "is-active": $router.currentRoute.name == "email_templates"}'
                              )
                            b-menu-item(
                                icon='pencil'
                                label="Maintenance Schedules"
                                tag="router-link"
                                :to='{ name: "maintenance_schedules" }',
                                :class='{ "is-active": $router.currentRoute.name == "maintenance_schedules"}'
                              )
                            b-menu-item(
                                icon='pencil'
                                label="Troubleshooting Info"
                                tag="router-link"
                                :to='{ name: "troubleshooting_info" }',
                                :class='{ "is-active": $router.currentRoute.name == "troubleshooting_info"}'
                              )
                            b-menu-item(
                              icon='pencil'
                              label="Torque Specifications"
                              tag="router-link"
                              :to='{ name: "torque_specifications" }',
                              :class='{ "is-active": $router.currentRoute.name == "torque_specifications"}'
                            )
                            b-menu-item(
                                icon='pencil'
                                label="Pump Operating Instructions"
                                tag="router-link"
                                :to='{ name: "operating_instructions" }',
                                :class='{ "is-active": $router.currentRoute.name == "operating_instructions"}'
                              )
            template(v-if='!$auth.is.ieqAdmin && $auth.currentUser.customerAdmin')
              b-collapse( :open="true" aria-id="contentIdForAdmin")
                template( #trigger="props")

                    div.menu-label( aria-controls="contentIdForAdmin"
                        :aria-expanded="props.open") Admin
                      b-icon( :icon="!props.open ? 'chevron-right' : 'chevron-down'")

                ul.menu-list
                  li: router-link(
                    :to='{ name: "customer", params: { id: $auth.currentCustomer.id } }',
                    :class='{ "is-active": $router.currentRoute.name == "customer" }'
                  )
                    b-icon(icon='account-star')
                    span Customer Detail
                  li: router-link(
                    :to='{ name: "users" }',
                    :class='{ "is-active": $router.currentRoute.name == "users" || $router.currentRoute.name == "user" }'
                  )
                    b-icon(icon='account-multiple')
                    span Users


      .wrapper-main.cxi-watermark1(
        :class='[{ "hero is-large is-fullheight brand-gradient-1": !$auth.isSignedIn }, { "sidebar-active": showMainSidebar }]'
      )
        //- b-modal(
        //- :active.sync='customUserDialog'
        //- :hasModalCard='true'
        //- ).align_to_top
        //-   .modal-card
        //-     .modal-card-head
        //-       p.modal-card-title Customize Your Dashboard
        //-     section.modal-card-body
        //-       h1.subtitle.is-size-5.mb-5 Select cards to show in your dashboard:

        //-       ul
        //-         li(v-for='value, key in user_dashboard_config.card_visibility' :key="key")

        //-           b-field.mb-1
        //-             b-switch(
        //-               v-model='user_dashboard_config.card_visibility[key]',
        //-             ) {{ key }}
        //-     footer.modal-card-foot.has-content-justified
        //-       a.button(@click='customUserDialog = false') Cancel
        //-       a.button.is-primary(@click='saveUserDashboard') Save

        section.top_message_bar(v-if="$auth.isBronzeUser")
            div.level-left
              div.is-flex.is-flex-direction-column
                //- h2.is-size-5 Welcome to your trial account
                h2.is-size-5 To access full catalog and additional features upgrade to a paid plan.
            div.level-right
              a.button.btn_ripple(class="button is-warning" :href="UPGRADE_LINK" target="_blank") Get Started
        main.section(:class='{ "is-Login": !$auth.isSignedIn }')
          .columns
            .column
              router-view
        //- .fab(
        //-   @click='showTodoListSidebar()',
        //-   :class='{ "is-active": showTodoList }'
        //- )
        //-   span Notes

        aside.todoListSidebar(
          :class='[{ "is-active": showTodoList }]',
          v-if='$auth.isSignedIn'
        )
          .todoWrapper
            .sidebarHeader
              .sidebarTitleWrap
                h6.smallTitle Notes
                h3.sidebarTitle My Notes
              //- a.undo.button.is-light.is-rounded(v-if="showUndo" @click="undoNote()")
              //-   b-icon(icon="undo")
              a.close.button.is-light.is-rounded(@click='showTodoListSidebar()')
                b-icon(icon='close')
            b-field.addToDoItemWrap
              b-input.toDoItem(
                v-model='noteName',
                placeholder='Add a note',
                expanded,
                rounded,
                icon='plus'
              )
              b-button.addToDoItem.is-primary(@click='addNote()')
                b-icon(icon='subdirectory-arrow-left')

            .listWrapper
              .todoListWrap
                ul.todoList(v-for='(note, index) in completedNotes')
                  li: .todoListItemWrap
                    .todolistIcon
                      a.button.is-light.is-rounded(
                        @click='markAsComplete(note)',
                        title='Mark as complete'
                      )
                        b-icon(icon='checkbox-blank-circle-outline')
                    .todolistItem {{ note.name }}
                    .todolistAction
                      a.edit.button.is-light.is-rounded(
                        @click='editTodoItem(note)'
                      )
                        b-icon(icon='pencil')

              .completedListWrap
                b-collapse.card(:open='false', aria-id='completedTodos')
                  .card-header(
                    slot='trigger',
                    role='button',
                    aria-controls='completedTodos',
                    @click='updateIsOpen()'
                  )
                    p.card-header-title Completed ({{ notCompletedNotes.length }})
                    span.card-header-icon
                      b-icon(:icon='isOpen ? "chevron-down" : "chevron-up"')
                  .card-content
                    ul.completedList.todoList(
                      v-for='(note, index) in notCompletedNotes'
                    )
                      li: .todoListItemWrap
                        .todolistIcon
                          b-icon(icon='check')
                        .todolistItem {{ note.name }}
                        .todolistAction
                          a.delete-item.button.is-light.is-rounded(
                            @click='confirmNoteDelete(note.id)'
                          )
                            b-icon(icon='delete')

        aside.todoEditItemWin(
          :class='[{ "is-active": showEditItemWin }]',
          v-if='$auth.isSignedIn'
        )
          .sidebarHeader
            a.back(@click='backToList()')
              b-icon(icon='arrow-left')
            .sidebarHeaderActions
              a.save.button.is-light.is-rounded(@click='saveNote()') Save
                //- b-icon(icon="save")
              a.delete-item.button.is-light.is-rounded(
                @click='confirmNoteDelete(note.id)'
              )
                b-icon(icon='delete')
              a.close.button.is-light.is-rounded(@click='closeTodoListSidebar()')
                b-icon(icon='close')
          .todoEditItemWrap
            b-field
              b-input(v-model='note.name', placeholder='Enter Title', expanded)
            b-field
              VueEditor(
                v-model='note.description',
                :editMode='true',
                label='Description'
              )

        footer.footer
          p.has-text-centered Copyright ©2024, IEQ Industries, a Gallagher Fluid Handling Company. All rights reserved, <br>*Used under license from IEQ Industries, a Gallagher Fluid Handling Company.
      b-icon(type="is-primary" size="is-large" class="to-top" @click.native="scrollToTop" icon="chevron-up-circle" role="button")




      TheAttachmentDisplayModal
    template(v-if='!$auth.isSignedIn')
      section.hero.is-large.is-fullheight.brand-gradient-1
        .hero-body.cxi-watermark.is-paddingless
          .container
            .columns.is-vcentered.is-centered
              .column.is-4
                router-view

  </template>

<script>
import TheAttachmentDisplayModal from './components/TheAttachmentDisplayModal'
import NProgress from 'nprogress'
import { SnackbarProgrammatic as Snackbar } from 'buefy' // deepti
import { VueAutosuggest } from 'vue-autosuggest'
import { CXI_constants } from './_main/constants.js'
// const initOrder = [
//   'Pumps',
//   'Equipment',
//   'Locations',
//   'Kits',
//   'Quotes',
//   'Orders',
//   'Jobs'
// ]
// const initCardVisibility = {
//   Pumps: true,
//   Equipment: true,
//   Locations: true,
//   Kits: true,
//   Quotes: true,
//   Orders: true,
//   Jobs: true
// }
const debounce = function debounce(fn, delay) {
  var timeoutID = null
  return function() {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function() {
      fn.apply(that, args)
    }, delay)
  }
}

export default {
  // uncomment below - Atul
  mixins: [
    // require("./mixins/Search").locations,
    // require("@/mixins/Search").assetTags,
  ],
  components: { TheAttachmentDisplayModal, VueAutosuggest },
  data() {
    return {
      showSearchOnMobile: false,
      showNavOnMobile: false,
      showNavOnDesktop: true,
      showTopNavOnMobile: false,
      showMainSidebar: false,
      showTodoList: false,
      showEditItemWin: false,
      isOpen: false,
      notes: [],
      completedNotes: [],
      notCompletedNotes: [],
      note: {},
      noteName: '',
      showUndo: false,
      searchText: '',
      dSearchText: '',
      searchDataLoading: true,
      searchDataList: [],
      searchResultCount: 0,
      selected: '',

      sectionConfigs: {
        default: {
          limit: 4,
          ulClass: { 'data-darren': true },
          liClass: { 'elf-row': true }
        },
        Elf: {
          limit: 4
        }
      },
      inputProps: {
        id: 'autosuggest__input',
        placeholder: 'Search Catalog and Asset items'
      },

      authToken: ''
      // customUserDialog: false,
      // user_dashboard_config: {
      //   id: this.$auth.currentUser.user_dashboard_config_id,
      //   user: this.$auth.currentUser.id,
      //   user_id: this.$auth.currentUser.id,
      //   card_visibility: initCardVisibility,
      //   tab_order: initOrder
      // },
      // error: '',
      // config: {
      //   saveDataPath: this.$routes.user_dashboard_configs_path,
      //   updateDataPath: this.$routes.user_dashboard_config_path
      // }
    }
  },

  methods: {
    // customUserDialogOpen() {
    //   this.customUserDialog = true
    //   this.error = ''
    // },
    // async saveUserDashboard() {
    //   await this.create_update_cards_order()
    //   this.customUserDialog = false
    // },
    // customDialogOpen() {
    //   this.customDialog = true
    //   this.error = ''
    // },
    // async create_update_cards_order() {
    //   let _this = this

    //   if (this.is_new) {
    //     await this.$http
    //       .post(this.config.saveDataPath(), this.user_dashboard_config)
    //       .then(response => {
    //         Snackbar.open({
    //           message: 'Configuration updated',
    //           queue: false
    //         })
    //       })
    //       .catch(error => {
    //         console.log('error ', error)
    //       })
    //   } else {
    //     await this.$http
    //       .put(
    //         this.config.updateDataPath(this.user_dashboard_config.id),
    //         this.user_dashboard_config
    //       )
    //       .then(response => {
    //         Snackbar.open({
    //           message: 'Configuration updated',
    //           queue: false
    //         })
    //       })
    //       .catch(error => {
    //         console.log('error ', error)
    //       })
    //   }
    // },
    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },
    updateIsOpen() {
      this.isOpen = !this.isOpen
    },
    showTodoListSidebar() {
      this.note = {}
      this.getNotes()
      this.showTodoList = !this.showTodoList
      // console.log(this.showTodoList);
    },
    editTodoItem(note) {
      this.showEditItemWin = !this.showEditItemWin
      // console.log("edit item clicked", note);
      this.note = note
    },
    backToList() {
      this.showEditItemWin = false
      this.note = {}
    },
    closeTodoListSidebar() {
      this.showTodoList = false
      this.showEditItemWin = false
    },
    showSearchBar() {
      if (screen.width <= 760) {
        this.showSearchOnMobile = !this.showSearchOnMobile
        console.log(this.showSearchOnMobile)
        //return true;
      } else {
        console.log(this.showSearchOnMobile)
        //return false;
      }
    },
    showSidebarNav() {
      if (screen.width <= 760) {
        this.showMainSidebar = this.showNavOnMobile
        this.showNavOnMobile = !this.showNavOnMobile
        console.log('ismobile-true')
        //return true;
      } else {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
        ) {
          this.showMainSidebar = this.showNavOnMobile
          this.showNavOnMobile = !this.showNavOnMobile
          console.log('ismobile-true, touch device true')
        } else {
          this.showMainSidebar = this.showNavOnDesktop
          this.showNavOnDesktop = !this.showNavOnDesktop
          console.log('ismobile-false')
        }

        //return false;
      }
      // return isMobile()? showNavOnMobile : showNavOnDesktop;
      // this.showMainSidebar = this.isMobile() ? this.showNavOnMobile : this.showNavOnDesktop;
      // if(this.isMobile()){
      //   this.showNavOnMobile = !this.showNavOnMobile;
      // }else{
      //   this.showNavOnDesktop = !this.showNavOnDesktop;
      // }
      return this.showMainSidebar
    },
    getNotes() {
      this.$http.get(this.$routes.notes_path()).then(res => {
        console.log('Res Notes --> ', res)
        var notes = []
        notes = res.data.results
        this.completedNotes = notes.filter(function(note) {
          return note.isCompleted == false
        })
        this.notCompletedNotes = notes.filter(function(note) {
          return note.isCompleted == true
        })
        this.paginationInfo = res.data.pagination
      })
    },
    saveNote() {
      this.note.created_by = this.$auth.currentUser.id
      this.note.updated_by = this.$auth.currentUser.id

      if (this.note.id !== undefined) {
        // Existing Note
        this.$http
          .put(this.$routes.note_path(this.note.id), {
            ...this.note
          })
          .then(() => {
            this.note = {}
            this.noteName = ''
            this.getNotes()
            this.showEditItemWin = false
            Snackbar.open({
              message: `Note Saved Successfully`,
              queue: false
            })
          })
          .catch(error => {
            // NProgress.done()
            this.error = error.data.message
            console.log('error ', error.data)
          })
      } else {
        //New Note
        this.note.isCompleted = false // deepti
        this.$http
          .post(this.$routes.notes_path(), {
            ...this.note
          })
          .then(() => {
            this.note = {}
            this.noteName = ''
            this.getNotes()
            this.showEditItemWin = false
            Snackbar.open({
              message: `Nice Job! You saved a record`,
              queue: false
            })
          })
          .catch(error => {
            // NProgress.done()
            this.error = error.data.message
            console.log('error ', error.data)
          })
      }
      // console.log("note --> ", this.note);
      // this.note.user_id = this.$auth.currentUser.id //- Deepti
    },
    showImg() {
      $('.img1').toggle()
      $('.img2').toggle()
    },
    showImg2() {
      $('.img1').toggle()
      $('.img2').toggle()
    },
    addNote() {
      this.note.name = this.noteName
      if (this.note.name != '') {
        this.saveNote()
      }
    },
    confirmNoteDelete(id) {
      // console.log("id ", id);
      var _this = this
      this.$buefy.dialog.confirm({
        title: 'Deleting note',
        message:
          'This will <b>remove</b> your note from your library. Are you sure?',
        confirmText: 'Delete Note',
        type: 'is-danger',
        hasIcon: true,
        onConfirm() {
          this.$http.delete(this.$routes.note_path(id)).then(() => {
            _this.showEditItemWin = false
            _this.getNotes()
            _this.note = {}
            this.$buefy.toast.open('Record deleted!')
          })
        }
      })
    },
    markAsComplete(note) {
      note.isCompleted = true
      this.note = note
      this.saveNote()
    },
    // clickHandler(item) {
    //   // console.log("clickHandler item ", item);
    // },
    onSelected(...args) {
      const item = args[0]
      console.log('item', item)
      if (!item) {
        return
      }
      this.selected = item.item
      let itemType_id = item.item.itemType_id
      if (item.item.groupName == 'CATALOG') {
        if (itemType_id == 1) this.$navigate.part(item.item)
        if (itemType_id == 2) this.$navigate.kit(item.item)
        if (itemType_id == 3) this.$navigate.equipment(item.item)
        if (itemType_id == 4) this.$navigate.pump(item.item)
      } else if (item.item.groupName == 'LIBRARY') {
        // this.$navigate.equipment(item.item)
        if (itemType_id == 1) this.$navigate.userPart(item.item)
        if (itemType_id == 2) this.$navigate.userKit(item.item)
        if (itemType_id == 3) this.$navigate.userEquipment(item.item)
        if (itemType_id == 4) this.$navigate.userPump(item.item)
      }
    },
    onInputChange(text) {
      console.log(text)
      // this.getSearchResult(text);
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.text
    },
    focusMe(e) {
      console.log(e) // FocusEvent
    },
    getObjectForSearchResult(item, itemType_id, itemType, groupName, isAsset) {
      let text = ''
      if (itemType != '') text = itemType + '- '
      if (itemType == 'Pump') {
        // if(item.model) text +=  ' ' + item.model.number
        // if(item.manufacturer) text +=  ' ' + item.manufacturer.name
        if (item.name) text += ' ' + item.name
      } else if (itemType == 'User Pump') {
        if (item.model) text += ' ' + item.model.number
        if (item.manufacturer) text += ' ' + item.manufacturer.name
        if (item.name) text += ' ' + item.name
      } else {
        if (
          item.number &&
          (item.number != '' || item.number != null) &&
          item.name != item.number
        )
          text += item.number
        if (item.manufacturer) text += ' ' + item.manufacturer.name
        if (item.name && (item.name != '' || item.name != null))
          text += ' ' + item.name
      }

      if (isAsset) {
        if (
          item.location_name &&
          (item.location_name != '' || item.location_name != null)
        )
          text += ' ' + item.location_name
      }

      let obj = {}
      obj.id = item.id
      obj.name = item.name
      obj.text = text
      obj.number = item.number
      obj.itemType_id = itemType_id
      obj.type = itemType
      obj.groupName = groupName
      if (isAsset) obj.location_name = item.location_name
      return obj
    },
    getSearchResult(searchText = null) {
      console.log('searchText ', searchText)
      this.searchResultCount = 0
      this.searchDataList = []
      this.searchDataLoading = true

      var params = {
        search: searchText,
        library_id: this.$auth.currentCustomer$.id
      }
      new Promise(resolve => {
        var list = this.$http
          .get(this.$routes.search_path(), { params: params })
          .then(response => {
            // console.log('response', response.data.results)
            let apiResponseData = response.data.results
            this.searchResultCount = apiResponseData.total_results
            var partsArray = apiResponseData.parts.map(item => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(
                item,
                1,
                'Part',
                'CATALOG',
                false
              )
            })
            var kitsArray = apiResponseData.kits.map(item => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(
                item,
                2,
                'Kit',
                'CATALOG',
                false
              )
            })
            var equipmentsArray = apiResponseData.equipments.map(item => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(
                item,
                3,
                'Equipment',
                'CATALOG',
                false
              )
            })
            var pumpsArray = apiResponseData.pumps.map(item => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(
                item,
                4,
                'Pump',
                'CATALOG',
                false
              )
            })
            var userPartsArray = apiResponseData.user_parts.map(item => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(
                item,
                1,
                'User Part',
                'LIBRARY',
                true
              )
            })
            var userKitsArray = apiResponseData.user_kits.map(item => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(
                item,
                2,
                'User Kit',
                'LIBRARY',
                true
              )
            })
            var userEquipmentsArray = apiResponseData.user_equipments.map(
              item => {
                //args- item,itemType_id,itemType,groupName,isAsset
                return this.getObjectForSearchResult(
                  item,
                  3,
                  'User Equipment',
                  'LIBRARY',
                  true
                )
              }
            )
            var userPumpsArray = apiResponseData.user_pumps.map(item => {
              //args- item,itemType_id,itemType,groupName,isAsset
              return this.getObjectForSearchResult(
                item,
                4,
                'User Pump',
                'LIBRARY',
                true
              )
            })
            let itemsArray = _.concat(
              partsArray,
              kitsArray,
              equipmentsArray,
              pumpsArray,
              userPartsArray,
              userKitsArray,
              userEquipmentsArray,
              userPumpsArray
            )
            resolve(list)
            return itemsArray
          })
          .then(list => {
            // console.log('list',list)
            // var uniq = _.uniqBy(list, 'groupName');
            // var groups  = _.map(uniq, _.partialRight(_.pick, ['groupName']))
            //let groups = [...new Set(list.map(item => item.groupName))]; // get Dynamic Group name - This is working
            // console.log('uniq groups',groups1)
            var groups = ['LIBRARY', 'CATALOG']
            ;(this.searchDataList = groups.map(g => ({
              label: g,
              name: g,
              data: list.filter(d => d.groupName === g)
            }))),
              (this.searchDataLoading = false)
            if (this.searchResultCount == 0) {
              this.searchDataList = groups.map(g => ({
                label: g,
                name: g,
                data: [{ text: 'NO ITEM FOUND' }]
              }))
            }
            console.log('group list', this.searchDataList)
          })
          .catch(err => {
            console.error(err)
          })
        //console.log('list', list)
      })
    },
    scrollToTop() {
      console.log('click')
      document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      document.activeElement.blur()
    },
    toggleScrollToTopBtn: debounce(function() {
      const rootElement = document.documentElement
      const scrollToTopBtn = document.querySelector('.to-top')
      let scrollTotal = rootElement.scrollHeight - rootElement.clientHeight

      if (rootElement.scrollTop / scrollTotal > 0.2) {
        // Show button
        scrollToTopBtn.classList.add('showBtn')
      } else {
        // Hide button
        scrollToTopBtn.classList.remove('showBtn')
      }
    }, 200),
    async set_user_pump_fields_customers_permissions() {
      NProgress.start()
      let query_params = {
        library_id: this.$auth.currentCustomer$.id
      }
      await this.$http
        .get(
          this.$routes.user_pump_fields_customers_permissions_by_customer_id_path(),
          { params: query_params }
        )
        .then(response => {
          console.log(response)
          if (response.status == 200) {
            // this.$auth.user_pump_customer_visibility =
            //   response.data.user_pump_fields
            this.$auth.currentCustomer$.user_pump_customer_visibility =
              response.data.user_pump_fields
          }
          console.log(this.$auth.currentCustomer$.user_pump_customer_visibility)
          NProgress.done()
        })
        .catch(error => {
          console.log(error)
          NProgress.done()
        })
    }
  },
  mounted() {
    this.showMainSidebar = this.showSidebarNav()
    this.showSearchOnMobile = this.showSearchBar()
    // this.getNotes();
    // For Dark Theme
    // set 'app-background' class to body tag
    let bodyElement = document.body
    bodyElement.classList.add('app-background')

    document.addEventListener('scroll', this.toggleScrollToTopBtn)
  },
  async created() {
    this.isMobile()
    this.UPGRADE_LINK = CXI_constants.UPGRADE_LINK
    // console.log("Route Name")
    // console.log(this.$route.meta)
    // console.log("$router.history.current.path", this.$router.history.current.path)

    if (this.$cookies.isKey('auth_token')) {
      //this.$auth.authToken = this.$cookies.get('auth_token')
      await this.$http
        .get(this.$routes.init_path())
        .then(async response => {
          // console.log(response)
          if (response.status == 200) {
            _.assign(this.$auth, response.data)
            let switchCustomerId = localStorage.getItem('switchCustomerId')
            let currentCustomerId = this.$auth.currentCustomer$.id
            if (switchCustomerId != undefined) {
              // console.log('here')
              _.assign(
                this.$auth.currentCustomer$,
                JSON.parse(localStorage.getItem('switchedCustomerInfo'))
              )
              currentCustomerId = switchCustomerId
            }
            await this.$cart.getCartFromDB(currentCustomerId)
          }
        })
        .then(() => {
          debounce(async function(newVal) {
            await this.set_user_pump_fields_customers_permissions()
          }, 600)
        })
        .catch(error => {
          console.log(error)
          NProgress.done()
          if (error.response?.status == 401) {
            // Unauthorized
            this.$auth.isSignedIn = false
            this.$auth.forceLogin()
          }
        })
    }
    // else if ( this.$route.name == 'acceptInivation'){
    //   console.log("authNotRequired")
    //    // do nothing
    // } else if (this.$router.history.current.path != '/invitation/accept') {
    //   this.$router.push('/login')
    // }
  },
  watch: {
    searchText: debounce(function(newVal) {
      // this.dSearchText = newVal;
      if (newVal.length > 2) {
        this.getSearchResult(newVal)
      }
    }, 600)
    // $route(to, from) {
    //   console.log("currentRoute ", this.$router.currentRoute);
    // }
  }
}
</script>

<style lang="scss">
@import 'styles/application';

#nprogress {
  position: absolute;
  z-index: 1029;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);

  .bar {
    height: 4px !important;
    background: #0a2f64 !important;
  }
  .spinner {
    top: 50% !important;
    left: 50%;
  }
  .spinner-icon {
    height: 50px !important;
    width: 50px !important;
    border-top-color: #0a2f64 !important;
    border-left-color: #0a2f64 !important;
  }
}

.to-top {
  position: fixed !important;
  height: 40px !important;
  width: 40px !important;
  padding: 0 !important;
  bottom: 1.2rem;
  right: 1.5rem;
  z-index: 9;
  opacity: 0;
  transform: translateY(100px);
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 50%), 3px 3px 3px rgb(0 0 0 / 25%) !important;
  cursor: pointer !important;

  &.showBtn {
    opacity: 1;
    transform: translateY(0);
  }
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.brand-gradient-1 {
  background: #0a2f64;
  background: -webkit-linear-gradient(to right, #ffdd57, #0a2f64);
  background: linear-gradient(to right, #ffdd57, #0a2f64);
}

.cxi-watermark {
  background-image: url('images/cxi-logo-svg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.cxi-watermark1 {
  /* background-image: url('images/cxi-logo-svg1.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}

.navbar {
  box-shadow: 0px 2px 0px 0px hsl(0, 0%, 96%);

  .navbar-brand {
    // padding-left: 1.75rem;

    .navbar-item img {
      max-height: 3.25rem;
    }
  }
}

.wrapper-main {
  // display: flex;
  margin-top: 52px;
  position: relative;
  display: flex;
  flex-direction: column;
  &.sidebar-active {
    padding-left: 220px !important;
  }
}
@include touch {
  .wrapper-main.sidebar-active {
    padding-left: 0px !important;
  }
}
main.section {
  flex: 1;
  padding-top: 1rem;
  min-height: calc(100vh - 122px);
}

footer.footer {
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid var(--app-nav-icon-color);
  font-size: 12px;
  letter-spacing: 1px;
  color: var(--app-nav-text-color);
}

.search input.input {
  border-bottom: 1px solid var(--app-input-bdr-color);
}

.control.has-icons-left .icon {
  z-index: 1 !important;
  margin-top: 5px;
  .mdi-magnify {
    color: #607d8b;
  }
}

.search input::-webkit-input-placeholder {
  color: #0a2f64 !important;
  text-transform: capitalize;
}
.search input::-moz-placeholder {
  color: #0a2f64 !important;
  text-transform: capitalize;
}
.search input:-ms-input-placeholder {
  color: #0a2f64 !important;
  text-transform: capitalize;
}
.search input:-moz-placeholder {
  color: #0a2f64 !important;
  text-transform: capitalize;
}
.field-body {
  width: 100%;
}
.field-body > .field.has-addons {
  align-items: flex-end;
} /* Deepti this is to align expand and search on drawing page*/

.field.header-search {
  width: 100%;
  border-radius: inherit;
  margin-top: 0.5rem;
  input[type='text']#autosuggest__input {
    max-width: 300px;
    border-radius: 4px;
    padding-left: 28px;
  }

  input::focus {
    background: var(--app-header-search-bg);
  }
}

.fab {
  background: #0a2f64;
  width: 40px;
  height: 100px;
  border-radius: 10px 0 0 10px;
  text-align: center;
  color: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5), 3px 3px 3px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 10;
  bottom: 70px;
  right: 0px;
  display: inline-block;
  cursor: default;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;

  &:hover,
  &.is-active {
    background: #f0b428;
  }

  &.is-active {
    right: -65px;
  }

  span {
    // display: inline-block;
    // transform-origin: 0 0;
    // transform: rotate(90deg);
    // position: absolute;
    // top: 11px;
    // right: -35px;
    display: block;
    text-orientation: upright;
    writing-mode: vertical-rl;
    a {
      color: #fff;
    }
  }
}

.todoWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listWrapper,
.todoListWrap {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.listWrapper {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}

aside.todoListSidebar,
aside.todoEditItemWin {
  width: 0;
  position: absolute;
  right: -300px;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  background-color: var(--app-nav-bg-color);
  z-index: 10;
  margin-top: 10px;
  display: none;

  &.is-active {
    width: 300px;
    right: 0;
    height: calc(100vh - 80px);
    display: block;
  }

  .sidebarHeader {
    align-items: center;
    background-color: var(--app-card-bg-color);
    border-bottom: solid 1px var(--app-card-bdr-color);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 64px;
    padding: 0 6px 0 6px;
    position: relative;
    border-radius: 6px 0 0 0;

    .close {
      color: rgba(0, 0, 0, 0.54);
      font-weight: 100;
    }
    .save {
      color: rgba(0, 0, 0, 0.54);
      font-weight: 100;
    }
    .undo {
      color: rgba(230, 8, 8, 0.986);
      font-weight: 100;
    }
  }

  .button:not(.addToDoItem) {
    height: 40px;
    width: 40px;
    background: transparent;
    &:hover {
      background-color: #ecf0f6;
    }
  }
  .button.addToDoItem {
    background-color: #0a2f64;
    border-radius: 0;
  }
}

aside.todoEditItemWin {
  z-index: 11;
}

aside.todoListSidebar {
  .sidebarTitleWrap {
    box-flex: 1;
    flex-grow: 1;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;

    .smallTitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 6px 0 2px 0;
      color: #80868b;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }

    .sidebarTitle {
      font-weight: 500;
      color: var(--app-subtitle-color);
    }
  }
  .todoList li {
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: box-shadow, z-index;
    background-color: #fff;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    line-height: 20px;
    min-height: 48px;
    position: relative;
    z-index: 0;

    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      top: -1px;
      right: 0;
      bottom: 0;
      left: 48px;
      border-style: solid;
      border-color: #e0e0e0;
      border-width: 0 0 1px;
    }

    .todoListItemWrap {
      display: flex;
      box-flex: 1;
      flex-grow: 1;
      flex-wrap: nowrap;
      width: 100%;
      box-sizing: border-box;
      transition: padding-left 200ms;
      padding-left: 0;

      .todolistIcon {
        position: relative;
        margin: 0 -2px 0 6px;
        width: 48px;
        height: 48px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .todolistItem {
        align-self: center;
        display: flex;
        flex-direction: column;
        box-flex: 1;
        flex-grow: 1;
        padding: 12px 0;
        width: 0;
        position: relative;
        font-size: 14px;
      }
      .todolistAction {
        position: relative;
        flex-shrink: 0;
        width: 48px;

        .edit.button {
          opacity: 0;
          position: absolute;
          bottom: 4px;
          left: 0;
          height: 40px;
          width: 40px;
          margin-left: -4px;
        }
      }
    }

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
        0 2px 6px 2px rgba(60, 64, 67, 0.149);
      outline: 0;
      z-index: 2000;

      &::before {
        left: 0;
        border-top-width: 1px;
        border-bottom-color: #bdbdbd;
      }

      .todolistAction .button {
        opacity: 1 !important;
      }
    }
  }
}
.completedListWrap {
  .card {
    margin-top: 20px;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    border-top: 1px solid var(--app-card-bdr-color);
    border-radius: 0;
  }

  .card-header {
    padding: 0 5px 0 16px;
    // background: transparent;
    border-bottom: none;

    .card-header-title {
      font-size: 14px;
      font-weight: 500;
      // color: #5f6368;
      padding: 0;
    }
    .card-header-icon {
      color: #5f6368;
    }
  }

  .card-content {
    border: none;
    padding: 0;

    ul.completedList {
      width: 100%;
      .todoListItemWrap {
        align-items: center;
      }
    }
  }
}

.theme-switch-wrap {
  .switch .control-label {
    white-space: nowrap;
  }
}
aside.sidebar .menu-list a.logo {
  justify-content: center;
}
a.navbar-item.toggle-sidebar.is-desktop-icon-only {
  min-width: 80px;
  text-align: center;
  height: 61px;
  .icon {
    margin: 0;
    width: 100%;
    .mdi:before {
      font-size: 25px;
      font-weight: 100;
      color: var(--app-nav-icon-color);
    }
  }

  .pump-img:hover {
    background: red;
  }
}
@media (min-width: 768px) {
  .brand-logo {
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  a.navbar-item {
    margin-left: 1px;
    color: var(--app-text-color);
  }
  .navbar-burger {
    margin-left: 0.75rem !important;
  }
  .navbar-brand .brand-logo {
    margin: auto;
  }

  .navbar-burger:hover {
    background-color: transparent !important;
  }
  .navbar a.navbar-item.toggle-topnav:hover {
    color: var(--app-text-color);
  }

  .navbar a.navbar-item.toggle-topnav.is-active,
  .navbar-burger.is-active {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: var(--app-text-color);
    // position: fixed;
  }

  .navbar-menu {
    transition: all 0.3s ease-in-out;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  .navbar-menu:not(.showTopNavOnMobile):not(.showSearchOnMobile) {
    height: 0;
    padding: 0;
    display: block !important;
  }
  .navbar-menu.is-hidden-desktop:not(.showSearchOnMobile) .navbar-item {
    display: none;
  }

  .navbar-menu.showTopNavOnMobile,
  .navbar-menu.showSearchOnMobile {
    display: block;
    background-color: var(--app-mobile-sec-nav-bg-color);
    border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
    .button.is-white:hover {
      background-color: rgba(255, 255, 255, 0.1);
      .icon {
        color: #ffffff;
      }
    }
  }
  .navbar-menu.showTopNavOnMobile .navbar-end {
    display: flex;
    justify-content: flex-end;
    visibility: visible;
  }
  .navbar-menu:not(.showTopNavOnMobile) .navbar-end {
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
  }
}
aside.menu .menu-list a.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.menu-list span {
  text-align: center;
}
ul.menu li a {
  display: flex;
  align-items: center;
  align-content: baseline;
  justify-content: flex-start;
  padding: 0.5rem;
}
ul.menu li a:hover {
  background-color: var(--app-background-color);
}

.vue-popover {
  min-width: 220px;
}
[theme='dark'] .vue-popover {
  background: var(--app-card-bg-color);
}
[theme='dark'] .vue-popover.dropdown-position-right:before {
  border-right-color: var(--app-card-bg-color);
}
.top_message_bar {
  margin-top: 50px;
  background-color: #0a2f64;
  color: #fff !important;
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
  background: #0a2f64; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2f80ed,
    #0a2f64
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2f80ed,
    #0a2f64
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
@include desktop {
  // Styles applied
  // above $desktop
  .admin-menu {
    margin-bottom: 20px;
  }
}
.sub_menu > a,
.sub_menu_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div.menu-label {
  display: flex;
  align-content: center;
  align-items: center;
  & > span {
    display: flex;
    align-content: center;
    align-items: center;
  }
  .menu-label-text {
    color: #97a6bb;
  }
  .icon {
    width: 40px;
    color: #97a6bb;
  }
}
</style>
