import _ from 'lodash'

export function authHeader() {
  // return authorization header with jwt token
  // let user = JSON.parse(localStorage.getItem('auth'))
  const authToken = getCookieValue('auth_token')
  const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value

  // if (user && authToken) {
  if (authToken) {
    return { Authorization: 'Token ' + authToken, 'X-CSRFToken': csrftoken }
    // return { Authorization: 'JWT ' + authToken, 'X-CSRFToken': csrftoken }
  } else {
    return {}
  }
}

// Deepti
export function getCookieValue(a) {
  var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)')
  return b ? b.pop() : ''
}
