import Vue from 'vue'

function defaultData() {
  return {
    darkMode: false // for dark theme
  }
}

const tenant_theme = new Vue({
  name: 'tenant_theme',

  data() {
    return defaultData()
  },
  watch: {
    // For Dark Theme
    darkMode: function() {
      // add/remove class to/from html tag
      let htmlElement = document.documentElement
      if (this.darkMode) {
        localStorage.setItem('theme', 'dark')
        htmlElement.setAttribute('theme', 'dark')
      } else {
        localStorage.setItem('theme', 'light')
        htmlElement.setAttribute('theme', 'light')
      }
    }
  },
  // computed: {

  // },
  // created() {

  // },
  mounted() {
    // check for active theme
    let htmlElement = document.documentElement
    let theme = localStorage.getItem('theme')

    if (theme === 'dark') {
      htmlElement.setAttribute('theme', 'dark')
      this.darkMode = true
    } else {
      htmlElement.setAttribute('theme', 'light')
      this.darkMode = false
    }
  }
  // methods: {}
})

export default tenant_theme
