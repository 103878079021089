import Vue from 'vue'
import http from '../_main/http'

Vue.prototype.errors = {
  sendError(error, vm, info) {
    http.post("/appsignal_error_catcher", JSON.stringify({
      action: info,
      name: error.name,
      message: error.message,
      backtrace: (error.stack != null ? error.stack.split("\n") : undefined),
      path: window.location.pathname,
      environment: {
        agent: window.navigator.userAgent,
        platform: window.navigator.platform,
        vendor: window.navigator.vendor,
        screen_width: window.screen.width,
        screen_height: window.screen.height
      }
    })
    )
  }
}

export default Vue.prototype.errors
